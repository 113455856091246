import axios from 'axios';
import React, { useState, useRef, useEffect } from 'react';
import { countCharactersAndSegments } from '../components/utils';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import { useAuth } from '../components/context/AuthContext';
import { useParams, useNavigate } from 'react-router-dom';
import { BiArrowBack, BiMinus } from 'react-icons/bi'
import { BsInfoCircleFill, BsPlusLg } from 'react-icons/bs';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Dialog } from '@headlessui/react'
import Popup from '../components/Popup';
import Papa from "papaparse";
import { DateTime } from 'luxon';
import Loader from '../components/Loader';
import ResponseModal from '../components/ResponseModal';
import { MdCheck, MdClose, MdDelete, MdEdit } from 'react-icons/md';
import { FaChevronDown } from 'react-icons/fa';
import DialogBody from '../components/DialogBody';

const EditScheduleSmsPage = () => {

    const fileInputRef = useRef(null);
    const { accessToken, handleSignOut } = useAuth();
    const params = useParams();
    const navigate = useNavigate();

    const [isDialogLoading, setIsDialogLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [fromNumbers, setFromNumbers] = useState();
    const [file, setFile] = useState();
    const [isValidTime, setIsValidTime] = useState(true);
    const [formData, setFormData] = useState({
        file: undefined,
        file_name: '',
        image: undefined,
        image_url: '',
        from_number: '',
        message_body: '',
        column_name: '',
        is_multi_columns: false,
        is_headers: true,
        is_send_asap: false,
        date: '',
        time: '',
    });
    const [isMedia, setIsMedia] = useState(false);
    const [image, setImage] = useState('');
    const [isTimeRestriction, setIsTimeRestriction] = useState(true);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');

    // Error popup model state
    const [isError, setIsError] = useState(false);

    // CSV popup model state for upload
    const initialDialogState = {
        type: '',
        file: undefined,
        isHeaders: 'true',
        csv_name: '',
        column: true,
        column_name: '',
        isDelimited: 'true',
        delimited: ',',
        country_code: ''
    };
    const accordionVal = {
        invalidSection: false,
        validSection: false
    };
    const [isOpen, setIsOpen] = useState(false);
    const [currentDialogPage, setCurrentDialogPage] = useState(1);
    const [dialogData, setDialogData] = useState(initialDialogState);
    const [csvData, setCSVData] = useState([]);
    const [csvHeader, setCSVHeader] = useState([]);
    const [csvDataWithCode, setCSVDataWithCode] = useState([]);
    const [csvHeaderWithCode, setCSVHeaderWithCode] = useState([]);
    const [addHeaders, setAddHeaders] = useState({});
    const [addHeaderList, setAddHeaderList] = useState([]);
    const [invalidNumbers, setInvalidNumbers] = useState([]);
    const [validNumbers, setValidNumbers] = useState([]);
    const [whiteSpaceNumbers, setWhiteSpaceNumbers] = useState([]);
    const [invalidCodeNumbers, setInvalidCodeNumbers] = useState([]);
    const [code, setCode] = useState('');
    const [editCode, setEditCode] = useState('');
    const [newNumber, setNewNumber] = useState('');
    const [editNumIndex, setEditNumIndex] = useState(null);
    const [numIndex, setNumIndex] = useState(null);
    const [activeAccordion, setActiveAccordion] = useState(accordionVal);

    // CSV popup model state for create
    const initialCreateDialogState = {
        csv_name: '',
        isHeaders: 'true',
        header_name: '',
        number: '',
        data: [],
    };
    const [createDialogPage, setCreateDialogPage] = useState(1);
    const [toggleDialogPage, setToggleDialogPage] = useState(true);
    const [createDialogData, setCreateDialogData] = useState(initialCreateDialogState);
    const [tdCount, setTDCount] = useState(1);
    const [addedData, setAddedData] = useState({});

    const inputEl = useRef(null);

    const commonHeaders = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    useEffect(() => {
        inputEl?.current?.focus();
    }, [editNumIndex, numIndex]);

    useEffect(() => {
        handleAccordion();
        // eslint-disable-next-line
    }, [validNumbers, invalidCodeNumbers, whiteSpaceNumbers, invalidNumbers]);

    const fetchFromNumbers = () => {
        axios.get(`${BACKEND_BASE_URL}/api/from_numbers/`, { headers: commonHeaders })
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    setFromNumbers(response.data?.data)
                }
            }).catch((error) => {
                setIsLoading(false);
                console.log("error", error)
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
    };

    const fetchProfile = () => {
        axios.get(`${BACKEND_BASE_URL}/api/profile/`, {
          headers: commonHeaders,
        }).then((response) => {
          if (response.status === 200) {
            setIsTimeRestriction(response.data?.is_time_restriction);
          };
        }).catch((error) => {
          console.log("error", error)
          if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
            handleSignOut()
            navigate('/login')
          };
        })
      };

    useEffect(() => {
        if (params?.id) {
            setIsLoading(true)
            axios.get(`${BACKEND_BASE_URL}/api/schedule_sms/${params.id}`, { headers: commonHeaders })
                .then((response) => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        setFormData({
                            from_number: response.data?.from_number,
                            message_body: response.data?.message_body,
                            is_send_asap: response.data?.is_send_asap,
                            date: response.data?.date,
                            time: response.data?.time,
                            is_multi_columns: response.data?.is_multi_columns,
                            is_headers: response.data?.is_headers,
                            column_name: response.data?.column_name,
                            image_url: response.data?.image_url,
                            file_name: response.data?.file_name
                        })
                        let file = response.data?.file?.split('/').pop()
                        let image = response.data?.image ? response.data.image?.split('/').pop() : ''
                        setImage(image);
                        setFile(file);
                        setSelectedFile(response.data?.file_name);
                        fetchFromNumbers();
                        fetchProfile();
                        if (response.data?.image || response.data?.image_url) {
                            setIsMedia(true);
                        };
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    console.log("error", error)
                    if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                        handleSignOut()
                        navigate('/login')
                    };
                })
        };
        fetchFiles();
        // eslint-disable-next-line
    }, [params])

    useEffect(() => {
        if (csvHeader && csvHeader?.length > 1) {
            setDialogData({ ...dialogData, 'column': false, 'isHeaders': 'true' })
        } else if (csvData && csvData?.length && csvData[0]?.length > 1) {
            setDialogData({ ...dialogData, 'column': false, 'isHeaders': 'true' });
        } else {
            setDialogData({ ...dialogData, 'column': true })
        }
        // eslint-disable-next-line
    }, [csvHeader, csvData])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const sanJuanTimeZone = 'America/Puerto_Rico'; // Time zone for San Juan, Puerto Rico
        const sanJuanTime = DateTime.now().setZone(sanJuanTimeZone);
        const currentHour = sanJuanTime.hour;

        const url = `${BACKEND_BASE_URL}/api/schedule_sms/${params?.id}/`
        const headers = {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
        }
        if (!formData.is_send_asap || (!isTimeRestriction || (isTimeRestriction && formData.is_send_asap && currentHour >= 6 && currentHour < 20))) {
            setIsLoading(true);
            axios.put(url, formData, { headers: headers })
                .then((response) => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        // Set the API response
                        setApiResponse(response.data);
                    };
                }).catch((error) => {
                    setIsLoading(false);
                    setApiResponse(error?.message)
                    console.log("error", error);
                    if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                        handleSignOut()
                        navigate('/login')
                    };
                })
        } else {
            setIsError(true);
        };
    };

    const handleIsASAP = (event) => {
        let checked = event.target.checked;
        if (checked) {
            setFormData({ ...formData, date: '', time: '', is_send_asap: checked })
        } else {
            setFormData({ ...formData, is_send_asap: checked })
        };
    };

    const handleTime = (time) => {
        if (isTimeRestriction) {
            const [hours, minutes] = time.split(':').map(Number)
            if ((hours === 6 && minutes >= 0 && minutes <= 59) || (hours === 20 && minutes === 0)) {
                setIsValidTime(true);
            } else if (hours > 6 && hours < 20 && minutes >= 0 && minutes <= 59) {
                setIsValidTime(true);
            } else {
                setIsValidTime(false);
            };
        } else {
            setIsValidTime(true);
        };
    };

    const closeErrorDialog = () => {
        setIsError(true);
    };

    const closeCSVDialog = () => {
        setIsOpen(true);
    };

    const handleDialogChange = (e) => {
        const { name, value } = e.target;
        setDialogData({ ...dialogData, [name]: value });

        if (name === 'type') {
            setSelectedFile('');
            setFormData({ ...formData, 'file_name': '', 'is_headers': true, 'is_multi_columns': false, 'column_name': '' });
        };
        if (name === 'isHeaders') {
            setCSVData([]);
            setCSVHeader([]);
        };
    };

    const handleOpen = () => {
        setFormData({ ...formData, 'file': undefined, 'is_headers': true, 'is_multi_columns': false, 'column_name': '' });
        setDialogData(initialDialogState);
        setCreateDialogData(initialCreateDialogState);
        setToggleDialogPage(true);
        setCurrentDialogPage(1);
        setIsOpen(true);
        setCreateDialogPage(1);
        setAddHeaders({});
        setAddHeaderList([]);
        setCSVDataWithCode([]);
        setCSVHeaderWithCode([]);
        setCSVData([]);
        setCSVHeader([]);
        setTDCount(1);
        setAddedData({});
        setWhiteSpaceNumbers([]);
        setInvalidCodeNumbers([]);
        setCode('');
        setEditCode('');
        setNewNumber('');
        setEditNumIndex(null);
        setNumIndex(null);
    };

    const handleCancel = () => {
        setIsOpen(false);
        setIsDialogLoading(false);
        setDialogData(initialDialogState);
        setCurrentDialogPage(1);
        setCreateDialogData(initialCreateDialogState);
        setCreateDialogPage(1);
        setToggleDialogPage(true);
        setAddHeaders({});
        setAddHeaderList([]);
        setCSVDataWithCode([]);
        setCSVHeaderWithCode([]);
        setCSVData([]);
        setCSVHeader([]);
        setTDCount(1);
        setAddedData({});
        setSelectedFile('');
        setWhiteSpaceNumbers([]);
        setInvalidCodeNumbers([]);
        setCode('');
        setEditCode('');
        setNewNumber('');
        setEditNumIndex(null);
        setNumIndex(null);
    };

    const handleDialogNext = (page) => {
        // Set Dialog loading
        setIsDialogLoading(true);

        // Dialog page change for type upload
        if (dialogData.type === 'upload') {
            setCurrentDialogPage(page);
            setToggleDialogPage(true);
            page === 3 && dialogData.file && handleParse();

            let header = csvHeader?.length ? csvHeader : addHeaderList;
            let data = csvData;

            // set column name for only one column data
            if (page === 5 && dialogData.column && !dialogData.column_name && csvHeader?.length === 1) {
                setDialogData({ ...dialogData, 'column_name': csvHeader[0] })
            };

            // Adding country code to phone number column
            if ((page === 5 || page === 6) && dialogData.country_code?.length > 0) {
                let code = dialogData.country_code;
                let newDataList = data;

                if (dialogData.column) {
                    let newDataList = data.map(subArray => subArray.map(number => `${code} ${number}`));
                    setCSVDataWithCode(newDataList);
                } else if (!dialogData.column && dialogData.column_name) {
                    let columnName = dialogData.column_name
                    let headerIndex = header.indexOf(columnName);
                    if (headerIndex !== -1) {
                        newDataList = data.map(subArray => subArray.map((dt, i) => {
                            if (i === headerIndex) {
                                return `${code} ${dt}`
                            }
                            return dt;
                        }))
                    };
                    setCSVDataWithCode(newDataList);
                } else {
                    setCSVDataWithCode(data);
                };
            } else {
                setCSVDataWithCode(data);
                setCSVHeaderWithCode(header);
            };

            if (page === 6) {
                // Check CSV data valid or not
                checkCSVData();
                // Set default activeAccordion
                handleAccordion();
            } else {
                setInvalidNumbers([]);
                setValidNumbers([]);
                setInvalidCodeNumbers([]);
                setWhiteSpaceNumbers([]);
            };

            if ((page === 5 || page === 4) && csvData) {
                let count = csvData?.length ? csvData.length / 3000 : 1
                let sec = Math.trunc(count) * 1000
                setTimeout(function () {
                    setIsDialogLoading(false);
                }, sec);
            };
        };

        // Dialog page change for type create
        if (dialogData.type === 'create') {
            if (page === 1) {
                setCurrentDialogPage(page);
                setToggleDialogPage(true);
                setCreateDialogPage(page);
                setCreateDialogData(initialCreateDialogState);
            } else {
                setCreateDialogPage(page);
                setToggleDialogPage(false);
                if (page === 3) {
                    !createDialogData.is_placeholder && setCreateDialogData({ ...createDialogData, 'placeholder': null });
                    let headerList = addHeaderList;
                    if (headerList[0] !== createDialogData.header_name) {
                        headerList.splice(0, 0, createDialogData.header_name);
                    };
                    setAddHeaderList(headerList);
                    setIsDialogLoading(false);
                };
            };

            if (page === 5) {
                // Check CSV data valid or not
                checkCreateCSV();
                // Set default activeAccordion
                handleAccordion();
            } else {
                setInvalidNumbers([]);
                setValidNumbers([]);
                setInvalidCodeNumbers([]);
                setWhiteSpaceNumbers([]);
            };

            if (page === 4) {
                setIsDialogLoading(false);
            };
        };

        if (page === 1 || page === 2) {
            setIsDialogLoading(false);
        };
    };

    const handleParse = () => {
        const reader = new FileReader();

        reader.onload = async ({ target }) => {
            let csvData = [];
            if (dialogData.isHeaders === 'true') {
                const csv = Papa.parse(target.result, { header: true, skipEmptyLines: true });
                csv.meta.delimiter && setDialogData({ ...dialogData, 'delimited': csv.meta.delimiter })
                const parsedData = csv?.data;
                const columns = parsedData?.length ? Object.keys(parsedData?.[0]) : [];
                setCSVHeader(columns);
                parsedData?.map((num) => {
                    let columnData = [];
                    columns && columns?.map((col) => {
                        num?.[col] ? columnData.push(num[col]) : columnData.push('None')
                        return null;
                    })
                    csvData.push(columnData);
                    return null;
                })
                setCSVData(csvData);
                setIsDialogLoading(false);
            } else {
                const csv = Papa.parse(target.result, { header: false, skipEmptyLines: true });
                csv.meta.delimiter && setDialogData({ ...dialogData, 'delimited': csv.meta.delimiter })
                const parsedData = csv?.data;
                parsedData?.map((num) => {
                    num[0] ? csvData.push(num) : csvData.push(['None'])
                    return null;
                })
                setCSVData(csvData);
                setIsDialogLoading(false);
            };
        };
        reader.readAsText(dialogData.file);
    };

    const validCSVName = () => {
        if (dialogData.csv_name) {
            return /^[^ ';:"/`~,.]+$/.test(dialogData.csv_name);
        };
        return true;
    };

    const handleDelimiter = (val) => {
        const reader = new FileReader();

        reader.onload = async ({ target }) => {
            let csvData = [];
            if (dialogData.isHeaders === 'true') {
                const csv = Papa.parse(target.result, { header: true, delimiter: val, skipEmptyLines: true });
                const parsedData = csv?.data;
                const columns = parsedData?.length ? Object.keys(parsedData?.[0]) : [];
                setCSVHeader(columns);
                parsedData?.map((num) => {
                    let columnData = [];
                    columns && columns?.map((col) => {
                        num?.[col] ? columnData.push(num[col]) : columnData.push('None')
                        return null;
                    })
                    csvData.push(columnData);
                    return null;
                })
                setCSVData(csvData);

            } else {
                const csv = Papa.parse(target.result, { header: false, delimiter: val, skipEmptyLines: true });
                const parsedData = csv?.data;
                parsedData?.map((num) => {
                    num[0] ? csvData.push(num) : csvData.push(['None'])
                    return null;
                })
                setCSVData(csvData);
            };
        };
        reader.readAsText(dialogData.file);
    };

    const handleFinish = () => {
        let headers = csvHeaderWithCode ? csvHeaderWithCode : csvHeader;
        let data = csvDataWithCode ? csvDataWithCode : csvData;
        let csvName = dialogData.csv_name ? `${dialogData.csv_name}.csv` : dialogData.file ? dialogData.file?.name : 'numbers.csv';
        let isMultiColumns = !dialogData.column;
        let isHeader = true;
        let columnName = dialogData.column_name;

        const csvRows = [];
        if (headers?.length) {
            csvRows.push(headers.join(','));
            isHeader = true
        } else if (dialogData.isHeaders === 'false') {
            isHeader = false;
        };

        data.forEach((item) => {
            if (item?.length) {
                csvRows.push(item.join(','));
            };
        });

        const newCSVData = csvRows.join('\n');
        const blobData = new Blob([newCSVData], { type: 'text/csv' })

        const newFormData = new FormData();
        newFormData.append('file', blobData, csvName)

        const file = newFormData.get('file');

        setFormData({ ...formData, 'file': file, 'is_headers': isHeader, 'is_multi_columns': isMultiColumns, 'column_name': columnName });
        setFile(csvName)
        setIsOpen(false);
    };

    const handleCreateDialogChange = (e) => {
        const { name, value } = e.target;
        setCreateDialogData({ ...createDialogData, [name]: value });
    };

    const validName = () => {
        if (createDialogData.csv_name) {
            return /^[^ ';:"/`~,.]+$/.test(createDialogData.csv_name);
        };
        return false;
    };

    const handleRemove = (index) => {
        setTDCount(tdCount - 1);
        let dataObj = addedData;
        delete dataObj[index];
        let newObj = {};
        Object.values(dataObj)?.forEach((value, index) => {
            newObj[index] = value;
        });
        setAddedData(newObj);
    };

    const handleCreate = () => {
        let headers = addHeaderList;
        let data = addedData;
        let csvName = `${createDialogData.csv_name}.csv`;
        let columnName = createDialogData.header_name;
        let isMultiColumns = createDialogData.is_placeholder;

        const csvRows = [];
        if (headers?.length) {
            csvRows.push(headers.join(','));
        };

        Object.values(data).forEach((item) => {
            csvRows.push(item.join(','));
        });

        const newCSVData = csvRows.join('\n');
        const blobData = new Blob([newCSVData], { type: 'text/csv' })

        const newFormData = new FormData();
        newFormData.append('file', blobData, csvName)

        const file = newFormData.get('file');

        setFormData({ ...formData, 'file': file, 'is_headers': true, 'is_multi_columns': isMultiColumns, 'column_name': columnName })
        setIsOpen(false);
        setFile(csvName);
    };

    const handleHeaders = (e) => {
        if (dialogData.type === 'upload') {
            const { name, value } = e.target;
            let headerList = addHeaderList;

            for (let i = 0; i <= name; i++) {
                if (i === +name) {
                    headerList[i] = value;
                } else if (!headerList[i]) {
                    headerList[i] = '';
                };
            };
            setAddHeaderList(headerList);
            setAddHeaders({ ...addHeaders, [name]: value });
        } else {
            const { name, value } = e.target;
            let headerList = addHeaderList;

            for (let i = 0; i <= name; i++) {
                if (i === +name) {
                    headerList[i] = value;
                } else if (!headerList[i]) {
                    headerList[i] = '';
                };
            };
            setAddHeaderList(headerList);
            setAddHeaders({ ...addHeaders, [name]: value });
        };
    };

    const validateCurrentPage4 = () => {
        let header = [];
        if ((csvHeader && csvHeader.length) || dialogData.column) {
            header = csvHeader;
        } else {
            let filteredList = addHeaderList.filter((ele) => ele?.length > 1)
            if (csvData?.length && filteredList?.length === csvData[0]?.length) {
                header = addHeaderList;
            } else {
                return false;
            };
        };
        if (dialogData.isDelimited && dialogData.delimited && csvData?.length &&
            ((dialogData.column) || (!dialogData.column && dialogData.column_name && header.includes(dialogData.column_name)))) {
            return true;
        } else {
            return false;
        };
    };

    const handleAddedData = (key, index, value) => {
        let headerList = addedData[key] ? addedData[key] : [];

        for (let i = 0; i <= index; i++) {
            if (i === +index) {
                headerList[i] = value;
            } else if (!headerList[i]) {
                headerList[i] = '';
            };
        };
        setAddedData({ ...addedData, [key]: headerList });
    };

    const validateCreatePage3 = () => {
        let placeholder = createDialogData.is_placeholder ? +createDialogData.placeholder + 1 : 1;
        if (addHeaderList?.length && (
            createDialogData.is_placeholder ? (placeholder === addHeaderList?.length && addedData?.[0] && addedData[0]?.length === placeholder)
                : addedData?.[0] && addedData[0]?.length && addedData[0][0]?.length >= 10)) {
            return true;
        } else {
            return false;
        };
    };

    const checkCSVData = (params, newNum) => {
        let headers = csvHeaderWithCode ? csvHeaderWithCode : csvHeader;
        let data = csvDataWithCode ? csvDataWithCode : csvData;
        let isHeader = true;
        let columnName = dialogData.column_name;
        let invalidNumber = [];
        let validNumbers = [];
        let WsNumbers = [];
        let iacNumbers = [];
        let phoneRegex = /^\+?[1-9]?\d{10,14}$/;
        let validPhoneRegex = /^\+?[1-9]\d{10,14}$/;

        if (headers?.length) {
            isHeader = true
        } else if (dialogData.isHeaders === 'false') {
            isHeader = false;
        };

        if ((params === 'deleteInvalidNumber' || params === 'deleteInvalidCode') && data && newNum) {
            data = data.filter((arr) => !arr.includes(newNum));
        };

        // Update state 
        if (isHeader) {
            if (headers && columnName) {
                let Index = headers.indexOf(columnName);

                if (Index !== -1) {
                    data && data.map((arr) => {
                        if (arr?.length) {
                            let number = arr?.[Index]

                            if (params === 'whitespace') {
                                number = number.replace(/\s/g, "");
                            } else if (params === 'code' && code?.length) {
                                if (invalidCodeNumbers.includes(number)) {
                                    number = code + number
                                };
                            } else if (params === 'invalidCode' && newNum === number && editCode?.length) {
                                number = editCode
                                setEditCode('');
                            } else if (params === 'invalidNumber' && newNum === number && newNumber?.length) {
                                number = newNumber
                                setNewNumber('');
                            };

                            if (!validPhoneRegex.test(number)) {
                                if (/\s/.test(number)) {
                                    WsNumbers.push(number);
                                } else if (!phoneRegex.test(number)) {
                                    invalidNumber.push(number);
                                } else {
                                    iacNumbers.push(number);
                                };
                            } else {
                                validNumbers.push(number);
                            };
                        }
                        return null;
                    })
                };
            };
        } else {
            data && data.map((arr) => {
                if (arr?.length && arr?.[0]) {
                    let number = arr?.[0]

                    if (params === 'whitespace') {
                        number = number.replace(/\s/g, "");
                    } else if (params === 'code' && code?.length) {
                        if (invalidCodeNumbers.includes(number)) {
                            number = code + number
                        };
                    } else if (params === 'invalidCode' && newNum === number && editCode?.length) {
                        number = editCode
                    } else if (params === 'invalidNumber' && newNum === number && newNumber?.length) {
                        number = newNumber
                    };

                    if (!validPhoneRegex.test(number)) {
                        if (/\s/.test(number)) {
                            WsNumbers.push(number);
                        } else if (!phoneRegex.test(number)) {
                            invalidNumber.push(number);
                        } else {
                            iacNumbers.push(number);
                        };
                    } else {
                        validNumbers.push(number);
                    };
                };
                return null;
            })
        };

        // Update CSV data
        if (params === 'whitespace') {
            if (csvDataWithCode && csvDataWithCode?.length) {
                let dataArr = csvDataWithCode;
                let trimmedArray = dataArr.map(arr => arr.map(element => element.toString().replace(/\s/g, "")));
                setCSVDataWithCode(trimmedArray);
            } else {
                let dataArr = csvData;
                let trimmedArray = dataArr.map(arr => arr.map(element => element.toString().replace(/\s/g, "")));
                setCSVData(trimmedArray);
            };
        } else if (params === 'code' && code?.length) {
            if (csvDataWithCode && csvDataWithCode?.length) {
                let dataArr = csvDataWithCode;
                let trimmedArray = dataArr.map(arr => arr.map((phone) => {
                    if (invalidCodeNumbers.includes(phone)) {
                        return code + phone;
                    }
                    return phone;
                }));
                setCSVDataWithCode(trimmedArray);
                setCode('');
            } else {
                let dataArr = csvData;
                let trimmedArray = dataArr.map(arr => arr.map((phone) => {
                    if (invalidCodeNumbers.includes(phone)) {
                        return code + phone;
                    }
                    return phone;
                }));
                setCSVData(trimmedArray);
                setCode('');
            };
        } else if (params === 'invalidCode' && editCode?.length) {
            if (csvDataWithCode && csvDataWithCode?.length) {
                let dataArr = csvDataWithCode;
                let trimmedArray = dataArr.map(arr => arr.map((phone) => {
                    if (phone === newNum) {
                        return editCode
                    };
                    return phone;
                }));
                setCSVDataWithCode(trimmedArray);
                setEditCode('');
            } else {
                let dataArr = csvData;
                let trimmedArray = dataArr.map(arr => arr.map((phone) => {
                    if (phone === newNum) {
                        return editCode
                    };
                    return phone;
                }));
                setCSVData(trimmedArray);
                setEditCode('');
            };
        } else if (params === 'invalidNumber' && newNumber?.length) {
            if (csvDataWithCode && csvDataWithCode?.length) {
                let dataArr = csvDataWithCode;
                let trimmedArray = dataArr.map(arr => arr.map((phone) => {
                    if (phone === newNum) {
                        return newNumber
                    };
                    return phone;
                }));
                setCSVDataWithCode(trimmedArray);
                setNewNumber('');
            } else {
                let dataArr = csvData;
                let trimmedArray = dataArr.map(arr => arr.map((phone) => {
                    if (phone === newNum) {
                        return newNumber
                    };
                    return phone;
                }));
                setCSVData(trimmedArray);
                setNewNumber('');
            };
        } else if ((params === 'deleteInvalidNumber' || params === 'deleteInvalidCode')) {
            if (csvDataWithCode && csvDataWithCode?.length) {
                let dataArr = csvDataWithCode;
                let filteredArray = dataArr.filter(arr => !arr.includes(newNum));
                setCSVDataWithCode(filteredArray);
            } else {
                let dataArr = csvData;
                let filteredArray = dataArr.filter(arr => !arr.includes(newNum));
                setCSVData(filteredArray);
            };
        } else if (params === 'deleteAllInvalidNumbers' && invalidNumbers) {
            if (csvDataWithCode && csvDataWithCode?.length) {
                let dataArr = csvDataWithCode;
                const filteredArray = dataArr.filter(subArray => !subArray.some(element => invalidNumbers.includes(element)));
                setCSVDataWithCode(filteredArray);
            } else {
                let dataArr = csvData;
                const filteredArray = dataArr.filter(subArray => !subArray.some(element => invalidNumbers.includes(element)));
                setCSVData(filteredArray);
            };
            invalidNumber = [];
        } else if (params === 'deleteAllInvalidCode' && invalidCodeNumbers) {
            if (csvDataWithCode && csvDataWithCode?.length) {
                let dataArr = csvDataWithCode;
                const filteredArray = dataArr.filter(subArray => !subArray.some(element => invalidCodeNumbers.includes(element)));
                setCSVDataWithCode(filteredArray);
            } else {
                let dataArr = csvData;
                const filteredArray = dataArr.filter(subArray => !subArray.some(element => invalidCodeNumbers.includes(element)));
                setCSVData(filteredArray);
            };
            iacNumbers = [];
        };

        let count = data?.length ?  data.length / 3000 : 1
        let sec = Math.trunc(count) * 1000
        setTimeout(function () {
            setIsDialogLoading(false);
        }, sec);

        setInvalidNumbers(invalidNumber);
        setValidNumbers(validNumbers);
        setWhiteSpaceNumbers(WsNumbers);
        setInvalidCodeNumbers(iacNumbers);
    };

    const checkCreateCSV = (params, newNum) => {
        let headers = addHeaderList;
        let data = addedData;
        let columnName = createDialogData.header_name;
        let invalidNumber = [];
        let validNumbers = [];
        let WsNumbers = [];
        let iacNumbers = [];
        let phoneRegex = /^\+?[1-9]?\d{10,14}$/;
        let validPhoneRegex = /^\+?[1-9]\d{10,14}$/;

        // Update state
        if (headers && columnName) {
            let Index = headers.indexOf(columnName);

            if ((params === 'deleteInvalidNumber' || params === 'deleteInvalidCode') && data && newNum) {
                data = Object.values(data).filter((arr) => !arr.includes(newNum));
            };

            if (Index !== -1) {
                data && Object.values(data).forEach((item) => {
                    if (item?.length) {
                        let number = item?.[Index]

                        if (params === 'whitespace') {
                            number = number.replace(/\s/g, "");
                        } else if (params === 'code' && code?.length) {
                            if (invalidCodeNumbers.includes(number)) {
                                number = code + number
                            };
                        } else if (params === 'invalidCode' && newNum === number && editCode?.length) {
                            number = editCode
                            setEditCode('');
                        } else if (params === 'invalidNumber' && newNum === number && newNumber?.length) {
                            number = newNumber
                            setNewNumber('');
                        };

                        if (!validPhoneRegex.test(number)) {
                            if (/\s/.test(number)) {
                                WsNumbers.push(number);
                            } else if (!phoneRegex.test(number)) {
                                invalidNumber.push(number);
                            } else {
                                iacNumbers.push(number);
                            };
                        } else {
                            validNumbers.push(number);
                        };
                    };
                    return null;
                })
            };
        };

        // Update CSV data
        if (params === 'whitespace' && data) {
            data = Object.values(data).map(arr => arr.map((phone) => {
                if (whiteSpaceNumbers.includes(phone)) {
                    return phone.toString().replace(/\s/g, "")
                };
                return phone
            }));
        } else if (params === 'code' && code?.length && data) {
            data = Object.values(data).map(arr => arr.map((phone) => {
                if (invalidCodeNumbers.includes(phone)) {
                    return code + phone;
                };
                return phone;
            }));
            setCode('');
        } else if (params === 'invalidCode' && editCode?.length && data) {
            data = Object.values(data).map(arr => arr.map((phone) => {
                if (phone === newNum) {
                    return editCode
                };
                return phone;
            }));
            setEditCode('');
        } else if (params === 'invalidNumber' && newNumber?.length && data) {
            data = Object.values(data).map(arr => arr.map((phone) => {
                if (phone === newNum) {
                    return newNumber
                };
                return phone;
            }));
            setNewNumber('');
        } else if (params === 'deleteAllInvalidNumbers' && invalidNumbers && data) {
            data = Object.values(data).filter(arr => !arr.some(element => invalidNumbers.includes(element)));
            invalidNumber = [];
        } else if ((params === 'deleteInvalidNumber' || params === 'deleteInvalidCode')) {
            data = Object.values(data).filter(arr => !arr.includes(newNum));
        } else if (params === 'deleteAllInvalidCode' && invalidCodeNumbers && data) {
            data = Object.values(data).filter(arr => !arr.some(element => invalidCodeNumbers.includes(element)));
            iacNumbers = [];
        };

        let count = Object.keys(data)?.length ?  Object.keys(data).length / 3000 : 1
        let sec = Math.trunc(count) * 1000
        setTimeout(function () {
            setIsDialogLoading(false);
        }, sec);

        setInvalidNumbers(invalidNumber);
        setValidNumbers(validNumbers);
        setWhiteSpaceNumbers(WsNumbers);
        setInvalidCodeNumbers(iacNumbers);
        setAddedData(data);
    };

    const fetchFiles = () => {
        axios.get(`${BACKEND_BASE_URL}/api/uploaded_files/`, {
            headers: commonHeaders,
        }).then((response) => {
            if (response.status === 200) {
                setUploadedFiles(response.data);
            };
        }).catch((error) => {
            console.log("error", error)
            if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                handleSignOut()
                navigate('/login')
            };
        })
    };

    const handleFile = (data) => {
        setSelectedFile(data?.file);
        setDialogData({ ...dialogData, 'type': '' });
        setFormData({ ...formData, 'file_name': data?.file, 'is_headers': data?.is_headers, 'is_multi_columns': data?.is_multi_columns, 'column_name': data?.column_name });
    };

    const handleAccordion = (click) => {
        if (click === 'valid') {
            if ((invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0) || (validNumbers?.length <= 0)) {
                setActiveAccordion({ ...activeAccordion, validSection: !activeAccordion.validSection });
            } else {
                setActiveAccordion({ validSection: !activeAccordion.validSection, invalidSection: !activeAccordion.invalidSection });
            };
        } else if (click === 'invalid') {
            if ((invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0) || (validNumbers?.length <= 0)) {
                setActiveAccordion({ ...activeAccordion, invalidSection: !activeAccordion.invalidSection });
            } else {
                setActiveAccordion({ validSection: !activeAccordion.validSection, invalidSection: !activeAccordion.invalidSection });
            };
        } else {
            if (invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0) {
                setActiveAccordion({ validSection: true, invalidSection: true });
            } else if (validNumbers?.length <= 0) {
                setActiveAccordion({ validSection: true, invalidSection: true });
            } else if (invalidNumbers?.length > 0 || whiteSpaceNumbers?.length > 0 || invalidCodeNumbers?.length > 0) {
                setActiveAccordion({ validSection: false, invalidSection: true });
            } else if (validNumbers?.length > 0) {
                setActiveAccordion({ validSection: true, invalidSection: false });
            };
        };
    };

    const { charCount, segments } = countCharactersAndSegments(formData.message_body);
    const charCountExceeded = segments > 1;

    return (
        <div className="min-h-screen bg-gray-100 flex justify-center py-5">
            {
                isLoading && <Loader />
            }
            {apiResponse && (
                <ResponseModal
                    apiResponse={apiResponse}
                    onClick={() => { setIsLoading(false); setApiResponse(null); navigate('/scheduled-sms'); }}
                />
            )}

            <Popup
                isOpen={isError}
                closeCSVDialog={closeErrorDialog}
                className="p-4 rounded-md"
            >
                <div>
                    <div className="text-center">
                        <Dialog.Title as="h3" className=" font-semibold leading-6 text-xl text-yellow-500">
                            Information for ASAP
                        </Dialog.Title>
                        <div className="otp-input-container mt-4 flex gap-2 justify-center text-gray-800">
                            You can not send the messages(ASAP) outside of 6 a.m. to 8 p.m.(Atlantic Standard Time)
                        </div>
                    </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 flex justify-end">
                    <button
                        type="button"
                        className="mt-3 outline-none bg-slate-400 text-white inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-1 sm:mt-0"
                        onClick={() => { setIsError(false); }}
                    >
                        Close
                    </button>
                </div>
            </Popup>

            <Popup
                isOpen={isOpen}
                closeCSVDialog={closeCSVDialog}
            >
                {currentDialogPage === 1 && toggleDialogPage && (
                    <div>
                        <div className='border-[5px] border-[#2e46f36b]'>
                            <div>
                                <Dialog.Title as="h3" className="text-base text-[19px] text-left p-[14px_3px_14px_10px] shadow-md font-[400] capitalize leading-6 text-slate-800 bg-[#e9e9e9]">
                                    List Type
                                </Dialog.Title>
                            </div>
                            <DialogBody isDialogLoading={isDialogLoading} className='p-2 text-center items-center justify-center'>
                                <div className='text-left xl:w-[400px] sm:w-[255px] md:w-[300px] lg:w-[330px] m-auto mt-7'>
                                    <span className='text-[18px]'>Select a Type</span>
                                    <div className='mt-[10px]'>
                                        <div>
                                            <input
                                                type='radio'
                                                id='upload'
                                                name='type'
                                                value='upload'
                                                checked={(dialogData.type === 'upload')}
                                                onChange={handleDialogChange}
                                                className='cursor-pointer accent-gray-600 outline-none'
                                            />
                                            <label htmlFor='upload' className='ml-1 text-slate-700 cursor-pointer'>Upload a csv file from local file system</label>
                                        </div>
                                        <div>
                                            <input
                                                type='radio'
                                                id='create'
                                                name='type'
                                                value='create'
                                                checked={(dialogData.type === 'create')}
                                                onChange={handleDialogChange}
                                                className='cursor-pointer accent-gray-600 outline-none'
                                            />
                                            <label htmlFor='create' className='ml-1 text-slate-700 cursor-pointer'>Create a new CSV file</label>
                                        </div>
                                    </div>
                                </div>
                                <span className='text-center text-[#000] font-[400] pt-3'>OR</span>
                                <div className='text-left xl:w-[400px] sm:w-[255px] md:w-[300px] lg:w-[330px] m-auto mt-2'>
                                    <div className='text-left ml-1 text-slate-700 mb-1'>Select previously uploaded files:</div>
                                    <div className='border border-slate-200 max-h-[305px] overflow-auto rounded-md'>
                                        {
                                            uploadedFiles?.length
                                                ? uploadedFiles?.map((obj, i) => (
                                                    <div
                                                        className={`text-[16px] p-1 pl-2 mx-1 my-1 hover:bg-gray-100 hover:rounded cursor-pointer ${selectedFile === obj?.file && 'bg-gray-300 hover:bg-gray-300 rounded'}`}
                                                        key={i}
                                                        onClick={() => handleFile(obj)}
                                                    >
                                                        {obj?.file}
                                                    </div>
                                                ))
                                                : <div className='text-[16px] p-1 pl-2 mx-1 my-1'>There are no previously uploaded files to select</div>
                                        }
                                    </div>
                                </div>
                            </DialogBody>
                            <div className='bg-[#e9e9e9] p-2'>
                                <div className='flex justify-between'>
                                    <div
                                        className='border px-4 py-2 h-[30px] select-none text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        <button className='font-[300] text-[15px] text-[#000]'>Cancel</button>
                                    </div>
                                    <div
                                        className={`border px-4 py-2 h-[30px] text-center flex items-center border-gray-300 ${(dialogData.type || selectedFile) ? 'bg-gray-100 hover:bg-gray-200 shadow-lg cursor-pointer' : 'cursor-not-allowed'}`}
                                        onClick={() => {
                                            if (selectedFile) {
                                                setIsOpen(false);
                                            } else {
                                                handleDialogNext(2);
                                            }
                                        }}
                                    >
                                        <button
                                            className={`font-[300] text-[15px] select-none text-[#000] ${!(dialogData.type || selectedFile) ? "cursor-not-allowed text-gray-500" : ""}`}
                                            disabled={!(dialogData.type || selectedFile)}
                                        >
                                            {selectedFile ? 'Done' : 'Next'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-2'>
                            <span className='text-[14px]'>List Type dialog for selecting a csv file or creating a new csv</span>
                        </div>
                    </div>
                )}

                {currentDialogPage === 2 && dialogData.type === 'upload' && (
                    <div>
                        <div className='border-[5px] border-[#2e46f36b]'>
                            <div>
                                <Dialog.Title as="h3" className="text-base text-[19px] text-left p-[14px_3px_14px_10px] shadow-md font-[400] capitalize leading-6 text-slate-800 bg-[#e9e9e9]">
                                    Name and Source
                                </Dialog.Title>
                            </div>
                            <DialogBody isDialogLoading={isDialogLoading} className='p-3 flex items-center justify-center'>
                                <div className='text-left max-w-[300px]'>
                                    <span className='text-[18px]'>Data source:</span>
                                    <div className='mt-[10px] mb-[40px]'>
                                        <div>
                                            <div className='flex items-center mb-2 border p-[4px] rounded-[0.25rem]'>
                                                <label htmlFor='csvFile' className='block'>
                                                    <span className='block mr-3 cursor-pointer rounded-[0.25rem] bg-slate-200 hover:bg-slate-300 border border-slate-200 bg-opacity-50 p-[6px_10px]'>
                                                        Upload
                                                    </span>
                                                </label>
                                                <div
                                                    className='block flex-grow overflow-hidden text-ellipsis whitespace-pre'
                                                >
                                                    {
                                                        dialogData.file ? dialogData.file?.name : <span>No file chosen</span>
                                                    }
                                                </div>
                                            </div>
                                            <input
                                                id="csvFile"
                                                name="file"
                                                type="file"
                                                accept=".csv"
                                                onChange={(e) => {
                                                    if (e.target.files[0] && e.target.files[0].size <= 5 * 1024 * 1024) {
                                                        setDialogData({ ...dialogData, 'file': e.target.files[0] });
                                                    } else {
                                                        e.target.value = null;
                                                        setDialogData({ ...dialogData, 'file': undefined });
                                                    }
                                                }}
                                                ref={fileInputRef}
                                                className="hidden w-full p-3 border border-gray-300 rounded accent-gray-600"
                                            />
                                            <p className="text-sm text-gray-500">
                                                Supported format .csv, Max file size 5MB
                                            </p>
                                        </div>

                                        <div className='mt-4'>
                                            <input
                                                type='radio'
                                                id='withHeader'
                                                name='isHeaders'
                                                value='true'
                                                defaultChecked={(dialogData.isHeaders === 'true')}
                                                onChange={handleDialogChange}
                                                className='cursor-pointer accent-gray-600'
                                            />
                                            <label htmlFor='withHeader' className='ml-1 mr-5 text-slate-700 cursor-pointer'>With Header</label>

                                            <input
                                                type='radio'
                                                id='withOutHeader'
                                                name='isHeaders'
                                                value='false'
                                                defaultChecked={(dialogData.isHeaders === 'false')}
                                                onChange={handleDialogChange}
                                                className='cursor-pointer accent-gray-600'
                                            />
                                            <label htmlFor='withOutHeader' className='ml-1 text-slate-700 cursor-pointer'>Without Header</label>
                                        </div>

                                        <div className='mt-4'>
                                            <input
                                                type='checkbox'
                                                id='oneColumn'
                                                name='column'
                                                checked={dialogData.column}
                                                onChange={(e) => setDialogData({ ...dialogData, 'column': e.target.checked })}
                                                className='cursor-pointer accent-gray-600'
                                            />
                                            <label htmlFor='oneColumn' className='ml-1 mr-5 text-slate-700 cursor-pointer'>File contains only one column?</label>
                                        </div>
                                    </div>
                                    <span className='text-[18px]'>Name and Format:</span>
                                    <div className='mt-[10px]'>
                                        <div>
                                            <input
                                                type='text'
                                                id='name'
                                                name='csv_name'
                                                placeholder='csv name'
                                                value={dialogData.csv_name}
                                                onChange={handleDialogChange}
                                                className='w-full p-1 border  outline-none border-gray-300 rounded'
                                            />
                                            <p className="text-sm text-gray-500">
                                                Change the uploaded file name (optional)
                                            </p>
                                        </div>
                                        <div className='mt-4'>
                                            <select
                                                id="csvFormat"
                                                name="format"
                                                className="w-full p-1 border border-gray-300 rounded outline-none"
                                            >
                                                <option value="">.csv</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </DialogBody>
                            <div className='bg-[#e9e9e9] p-2'>
                                <div className='flex justify-between'>
                                    <div
                                        className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        <button className='font-[300] text-[15px] text-[#000]'>Cancel</button>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div
                                            className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 cursor-pointer select-none'
                                            onClick={() => handleDialogNext(1)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Back</button>
                                        </div>
                                        <div
                                            className={`border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 ${dialogData.file && dialogData.isHeaders && validCSVName() ? "bg-gray-100 hover:bg-gray-200 shadow-lg cursor-pointer" : "cursor-not-allowed"}`}
                                            onClick={(dialogData.file && dialogData.isHeaders && validCSVName()) ? () => handleDialogNext(3) : null}
                                        >
                                            <button className={`font-[300] text-[15px] select-none text-[#000] ${!(dialogData.file && dialogData.isHeaders && validCSVName()) ? "cursor-not-allowed text-gray-500" : ""}`}>
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-2'>
                            <span className='text-[14px]'>Name and Source dialog is where you rename the csv and specify its sources</span>
                        </div>
                    </div>
                )}

                {currentDialogPage === 3 && dialogData.type === 'upload' && (
                    <div>
                        <div className='border-[5px] border-[#2e46f36b]'>
                            <div>
                                <Dialog.Title as="h3" className="text-base text-[19px] text-left p-[14px_3px_14px_10px] shadow-md font-[400] capitalize leading-6 text-slate-800 bg-[#e9e9e9]">
                                    Format Type
                                </Dialog.Title>
                            </div>
                            <DialogBody isDialogLoading={isDialogLoading} className={"flex flex-col justify-center"}>
                                <div className='text-left p-3 max-w-[400px] self-center w-full flex flex-grow'>
                                    <div className='mt-[10px] mb-[10px] flex items-center justify-center w-full'>
                                        <div>
                                            <div className='flex gap-3 mb-4 justify-between'>
                                                <div>
                                                    <input
                                                        type='radio'
                                                        id='delimited'
                                                        name='isDelimited'
                                                        value='true'
                                                        defaultChecked={(dialogData.isDelimited === 'true')}
                                                        onChange={handleDialogChange}
                                                        className='cursor-pointer accent-gray-600'
                                                    />
                                                    <label htmlFor='delimited' className='ml-1 text-slate-700 cursor-pointer'>Delimited</label>
                                                </div>

                                                <div>
                                                    <input
                                                        type='radio'
                                                        id='fixedWidth'
                                                        name='isDelimited'
                                                        value='false'
                                                        defaultChecked={(dialogData.isDelimited === 'false')}
                                                        onChange={(e) => { handleDialogChange(e); handleDelimiter(',') }}
                                                        className='cursor-pointer accent-gray-600'
                                                    />
                                                    <label htmlFor='fixedWidth' className='ml-1 mr-5 text-slate-700 cursor-pointer'>Fixed width</label>
                                                </div>
                                            </div>

                                            <div className='mb-4'>
                                                <input
                                                    type='checkbox'
                                                    id='firstLine'
                                                    name='first'
                                                    defaultChecked={dialogData.isHeaders === 'true'}
                                                    disabled
                                                    className='accent-gray-600'
                                                />
                                                <label htmlFor='firstLine' className='ml-1 text-slate-700'>First line contains column names</label>
                                            </div>
                                            <div></div>
                                            <div className='flex'>
                                                <label htmlFor='firstLine' className='mr-[10px] text-slate-700'>Input file encoding</label>
                                                <select
                                                    id="utf"
                                                    name="utf-8"
                                                    className="p-1 border border-gray-300 rounded min-w-[105px] outline-none"
                                                >
                                                    <option value="">UTF-8</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    csvData && csvData?.length ?
                                        <>
                                            <div className='overflow-x-auto w-full rounded-[8px] bg-white mb-5  px-3 sm:px-5'>
                                                <table className="table-auto mx-auto border w-full">
                                                    <thead className="bg-gray-100">
                                                        <tr>
                                                            <th className='text-[rgba(18,28,45,0.89)] text-[14px] border-b-[2px] border-solid relative py-1.5 pl-3 h-[22px]'>
                                                                {csvHeader?.length ? csvHeader.join(", ") : ''}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {csvData && csvData?.map((num, index) => (
                                                            <tr key={index}>
                                                                <td className='pl-2'>{num.join(", ")}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        : <div className='overflow-x-auto w-full bg-red-400 my-5 border p-2 text-lg text-center'>Uploaded file is empty/invalid.</div>
                                }
                            </DialogBody>
                            <div className='bg-[#e9e9e9] p-2'>
                                <div className='flex justify-between'>
                                    <div
                                        className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        <button className='font-[300] text-[15px] text-[#000]'>Cancel</button>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div
                                            className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 cursor-pointer select-none'
                                            onClick={() => handleDialogNext(2)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Back</button>
                                        </div>
                                        <div
                                            className={`border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 ${dialogData.isDelimited && csvData ? "bg-gray-100 hover:bg-gray-200 shadow-lg cursor-pointer" : "cursor-not-allowed"}`}
                                            onClick={dialogData.isDelimited && csvData ? () => handleDialogNext(4) : null}
                                        >
                                            <button className={`font-[300] text-[15px] select-none text-[#000] ${!(dialogData.isDelimited && csvData) ? "cursor-not-allowed text-gray-500" : ""}`}>
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-2'>
                            <span className='text-[14px]'>Format Type is where you set formatting options for the csv</span>
                        </div>
                    </div>
                )}

                {currentDialogPage === 4 && dialogData.type === 'upload' && (
                    <div>
                        <div className='border-[5px] border-[#2e46f36b]'>
                            <div>
                                <Dialog.Title as="h3" className="text-base text-[19px] text-left p-[14px_3px_14px_10px] shadow-md font-[400] capitalize leading-6 text-slate-800 bg-[#e9e9e9]">
                                    Format Options
                                </Dialog.Title>
                            </div>
                            <DialogBody isDialogLoading={isDialogLoading} className={"flex flex-col justify-center"}>
                                <div className='text-left p-3 max-w-[400px] self-center w-full flex flex-grow'>
                                    <div className='flex items-center justify-center w-full'>
                                        <div className='grid grid-cols-1 gap-2'>
                                            {
                                                dialogData.isDelimited === 'true' && (
                                                    <>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='comma'
                                                                name='delimited'
                                                                value=','
                                                                defaultChecked={(dialogData.delimited === ',')}
                                                                onChange={(e) => { handleDialogChange(e); handleDelimiter(',') }}
                                                                className='cursor-pointer accent-gray-600'
                                                            />
                                                            <label htmlFor='comma' className='ml-1 mr-5 text-slate-700 cursor-pointer'>Comma</label>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='tab'
                                                                name='delimited'
                                                                value='\t'
                                                                defaultChecked={(dialogData.delimited === '\t')}
                                                                onChange={(e) => { handleDialogChange(e); handleDelimiter('\t') }}
                                                                className='cursor-pointer accent-gray-600'
                                                            />
                                                            <label htmlFor='tab' className='ml-1 mr-5 text-slate-700 cursor-pointer'>Tab</label>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='semicolon'
                                                                name='delimited'
                                                                value=';'
                                                                defaultChecked={(dialogData.delimited === ';')}
                                                                onChange={(e) => { handleDialogChange(e); handleDelimiter(';') }}
                                                                className='cursor-pointer accent-gray-600'
                                                            />
                                                            <label htmlFor='semicolon' className='ml-1 mr-5 text-slate-700 cursor-pointer'>Semicolon</label>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='space'
                                                                name='delimited'
                                                                value=' '
                                                                defaultChecked={(dialogData.delimited === ' ')}
                                                                onChange={(e) => { handleDialogChange(e); handleDelimiter(' ') }}
                                                                className='cursor-pointer accent-gray-600'
                                                            />
                                                            <label htmlFor='space' className='ml-1 mr-5 text-slate-700 cursor-pointer'>Space</label>
                                                        </div>
                                                        <div>
                                                            <input
                                                                type='radio'
                                                                id='custom'
                                                                name='delimited'
                                                                value='custom'
                                                                defaultChecked={(dialogData.delimited === 'custom')}
                                                                onChange={handleDialogChange}
                                                                className='cursor-pointer accent-gray-600'
                                                            />
                                                            <label htmlFor='custom' className='ml-1 mr-5 text-slate-700 cursor-pointer'>Custom</label>
                                                            {
                                                                dialogData.delimited === 'custom' && (
                                                                    <input
                                                                        type='text'
                                                                        id='custom'
                                                                        name='custom'
                                                                        onChange={(e) => handleDelimiter(e.target.value)}
                                                                        className='w-10 border border-gray-300 rounded px-3 outline-none'
                                                                    />
                                                                )
                                                            }
                                                        </div>
                                                    </>
                                                )
                                            }
                                            {
                                                !dialogData.column && (
                                                    <div className='mt-1'>
                                                        <input
                                                            type='text'
                                                            id='columnName'
                                                            name='column_name'
                                                            placeholder='column name'
                                                            value={dialogData.column_name}
                                                            onChange={handleDialogChange}
                                                            className='w-full p-1 border border-gray-300 rounded outline-none'
                                                        />
                                                        <p className="text-sm text-gray-500">
                                                            Specify the column name of the phone number
                                                        </p>
                                                    </div>
                                                )
                                            }
                                            <div className='mt-1'>
                                                <input
                                                    type='text'
                                                    id='code'
                                                    name='country_code'
                                                    placeholder='code'
                                                    value={dialogData.country_code}
                                                    onChange={handleDialogChange}
                                                    className='w-14 p-1 border border-gray-300 rounded px-2 outline-none'
                                                />
                                                <p className="text-sm text-gray-500">
                                                    add country code to the phone number column(optional)
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {
                                    csvData && csvData?.length ?
                                        <>
                                            <div className='overflow-x-auto w-full rounded-[8px] bg-white mb-3 px-3 sm:px-5'>
                                                <table className="table-auto mx-auto border w-full">
                                                    <thead className="bg-gray-100">
                                                        <tr>
                                                            {
                                                                ((csvHeader && csvHeader.length) || dialogData.column) ? csvHeader?.map((header, index) => (
                                                                    <th key={index} className='text-[rgba(18,28,45,0.89)] border-r text-[14px] border-b-[2px] border-solid relative py-1.5 pl-2 h-[22px]'>
                                                                        {header}
                                                                    </th>
                                                                )) : csvData[0]?.length && csvData[0]?.map((dt, index) => (
                                                                    <th key={index} className='text-[rgba(18,28,45,0.89)] border-r text-[14px] border-b-[2px] border-solid relative py-1.5 pl-2 h-[22px]'>
                                                                        <input
                                                                            type='text'
                                                                            placeholder='header'
                                                                            name={index}
                                                                            value={addHeaders[index]}
                                                                            onChange={handleHeaders}
                                                                            className='w-full'
                                                                        />
                                                                    </th>
                                                                ))
                                                            }
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {csvData && csvData?.map((data, index) => (
                                                            <tr key={index}>
                                                                {
                                                                    data?.map((dt, i) => (
                                                                        <td key={i} className='pl-2 border-r'>{dt}</td>
                                                                    ))
                                                                }
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                        : <div className='overflow-x-auto w-full bg-red-400 my-5 border p-2 text-lg text-center'>Uploaded file is empty/invalid.</div>
                                }
                            </DialogBody>
                            <div className='bg-[#e9e9e9] p-2'>
                                <div className='flex justify-between'>
                                    <div
                                        className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        <button className='font-[300] text-[15px] text-[#000]'>Cancel</button>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div
                                            className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                            onClick={() => handleDialogNext(3)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Back</button>
                                        </div>
                                        <div
                                            className={`border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 ${validateCurrentPage4() ? "bg-gray-100 hover:bg-gray-200 shadow-lg cursor-pointer" : "cursor-not-allowed"}`}
                                            onClick={validateCurrentPage4() ? () => handleDialogNext(5) : null}
                                        >
                                            <button className={`font-[300] text-[15px] select-none text-[#000] ${!validateCurrentPage4() ? "cursor-not-allowed text-gray-500" : ""}`}>
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-2'>
                            <span className='text-[14px]'>In Format Options, choose Comma, Tab, Semicolon, Space or custom</span>
                        </div>
                    </div>
                )}

                {currentDialogPage === 5 && dialogData.type === 'upload' && (
                    <div>
                        <div className='border-[5px] border-[#2e46f36b]'>
                            <div>
                                <Dialog.Title as="h3" className="text-base text-[19px] text-left p-[14px_3px_14px_10px] shadow-md font-[400] capitalize leading-6 text-slate-800 bg-[#e9e9e9]">
                                    Final CSV
                                </Dialog.Title>
                            </div>
                            <DialogBody isDialogLoading={isDialogLoading} className={"flex flex-col justify-center"}>
                                <div className='overflow-x-auto w-full rounded-[8px] bg-white my-5 px-3 sm:px-5'>
                                    <table className="table-auto mx-auto w-full border">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                {
                                                    csvHeaderWithCode && csvHeaderWithCode.length > 0 ? (
                                                        csvHeaderWithCode?.map((header, index) => (
                                                            <th key={index} className='text-[rgba(18,28,45,0.89)] text-[14px] border-r border-b-[2px] border-solid relative py-1.5 pl-2 h-[22px]'>
                                                                {header}
                                                            </th>
                                                        ))
                                                    ) : (
                                                        csvHeader && csvHeader?.map((header, index) => (
                                                            <th key={index} className='text-[rgba(18,28,45,0.89)] text-[14px] border-r border-b-[2px] border-solid relative py-1.5 pl-2 h-[22px]'>
                                                                {header}
                                                            </th>
                                                        ))
                                                    )
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {
                                                csvDataWithCode && csvDataWithCode.length > 0 ? (
                                                    csvDataWithCode?.map((data, index) => (
                                                        <tr key={index}>
                                                            {
                                                                data?.map((dt, i) => (
                                                                    <td key={i} className='py-1 px-2 border-r'>{dt}</td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))) : (
                                                    csvData && csvData?.map((data, index) => (
                                                        <tr key={index}>
                                                            {
                                                                data?.map((dt, i) => (
                                                                    <td key={i} className='py-1 px-2 border-r'>{dt}</td>
                                                                ))
                                                            }
                                                        </tr>
                                                    ))
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </DialogBody>
                            <div className='bg-[#e9e9e9] p-2'>
                                <div className='flex justify-between'>
                                    <div
                                        className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        <button className='font-[300] text-[15px] text-[#000]'>Cancel</button>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div
                                            className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                            onClick={() => handleDialogNext(4)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Back</button>
                                        </div>
                                        <div
                                            className={`border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer`}
                                            onClick={() => handleDialogNext(6)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-2'>
                            <span className='text-[14px]'>Updated CSV file for upload</span>
                        </div>
                    </div>
                )}

                {currentDialogPage === 6 && dialogData.type === 'upload' && (
                    <div>
                        <div className='border-[5px] border-[#2e46f36b]'>
                            <div>
                                <Dialog.Title as="h3" className="text-base text-[19px] text-left p-[14px_3px_14px_10px] shadow-md font-[400] capitalize leading-6 text-slate-800 bg-[#e9e9e9]">
                                    List Tool
                                </Dialog.Title>
                            </div>
                            <DialogBody isDialogLoading={isDialogLoading}>
                                <div className='overflow-auto flex flex-col justify-center'>
                                    <div className='w-full rounded-[8px] bg-white p-3 sm:px-5'>
                                        <div className='border border-[#eee]'>
                                            <div
                                                className='bg-red-600 text-white text-[14px] relative py-1.5 px-2 font-[600] flex justify-between items-center cursor-pointer'
                                                onClick={() => handleAccordion('invalid')}
                                            >
                                                Invalid Numbers
                                                <FaChevronDown color='#fff' size={"18px"} className={`mr-1 transition-all ease-in duration-75 ${activeAccordion.invalidSection ? 'rotate-180': ''}`} />
                                            </div>
                                            <div className={`${activeAccordion.invalidSection ? 'grid' : 'hidden'} p-2 overflow-auto max-h-[347px] grid grid-cols-1 sm:grid-cols-2 items-start`}>
                                                {
                                                    invalidNumbers?.length > 0 && (
                                                        <table className="table-auto mx-auto w-full border mb-2">
                                                            <thead className='bg-gray-100'>
                                                                <tr>
                                                                    <th className='text-[14px] border-r border-b-[2px] border-solid relative py-1.5 pl-2 h-[22px]'>
                                                                        Invalid Format/Numbers
                                                                        <div
                                                                            className='float-right flex items-center justify-center cursor-pointer pr-2 text-[#FFFFFF] font-[500] text-[15 px] rounded'
                                                                            onClick={() => { setIsDialogLoading(true); checkCSVData('deleteAllInvalidNumbers'); }}
                                                                            data-tooltip-id='removeAllInvalidNumbers'
                                                                        >
                                                                            <MdDelete className='text-[#36454F] text-lg hover:text-[#0c090ae2]' />
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                                <ReactTooltip id="removeAllInvalidNumbers" content="Remove All" className='!bg-[#121c2d]' />
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                                {
                                                                    invalidNumbers?.map((number, index) => (
                                                                        <tr key={index}>
                                                                            <td className='border-r py-1 px-2'>
                                                                                <span className='flex items-center justify-between'>
                                                                                    {
                                                                                        numIndex === index ? (
                                                                                            <>
                                                                                                <input
                                                                                                    type='text'
                                                                                                    id='newNumber'
                                                                                                    name='newNumber'
                                                                                                    className='outline-none border border-slate-600 rounded-md px-1 max-w-[160px]'
                                                                                                    value={newNumber?.length ? newNumber : number}
                                                                                                    ref={inputEl}
                                                                                                    onChange={(e) => setNewNumber(e.target.value)}
                                                                                                />
                                                                                                <span>
                                                                                                    <MdClose className='hover:text-[#0c090ae2] mr-2 inline cursor-pointer text-[#9d2929]' onClick={() => { setNumIndex(null); setNewNumber(''); }} />
                                                                                                    <MdCheck className='hover:text-[#0c090ae2] inline cursor-pointer text-[#299d3c]' onClick={() => { checkCSVData('invalidNumber', number); setNumIndex(null); }} />
                                                                                                </span>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {number}
                                                                                                <div>
                                                                                                    <MdEdit className='hover:text-[#0c090ae2] mr-1 inline cursor-pointer text-[#36454F]' onClick={() => setNumIndex(index)} />
                                                                                                    <MdDelete className='hover:text-[#0c090ae2] inline cursor-pointer text-[#36454F]' onClick={() => checkCSVData('deleteInvalidNumber', number)} />
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )
                                                }
                                                {
                                                    whiteSpaceNumbers?.length > 0 && (
                                                        <table className="table-auto mx-auto w-full border mb-2">
                                                            <thead className='bg-gray-100'>
                                                                <tr>
                                                                    <th className='text-[14px] border-r border-b-[2px] border-solid relative flex justify-between items-center'>
                                                                        <span className='ml-[6px]'>Whitespaces</span>
                                                                        <div
                                                                            className='float-right px-1 py-1 mr-[6px] text-orange-500 hover:text-orange-600 underline font-[500] text-[15px] cursor-pointer rounded'
                                                                            onClick={() => { setIsDialogLoading(true); checkCSVData('whitespace'); }}
                                                                        >
                                                                            Remove Whitespaces
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                                {
                                                                    whiteSpaceNumbers?.map((number, index) => (
                                                                        <tr key={index}>
                                                                            <td className='border-r py-1 px-2'>{number}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )
                                                }
                                                {
                                                    invalidCodeNumbers?.length > 0 && (
                                                        <table className={`table-auto mx-auto w-full border mb-2 ${whiteSpaceNumbers?.length > 0 && invalidNumbers?.length > 0 ? "col-span-1 sm:col-span-2" : ""} `}>
                                                            <thead className='bg-gray-100'>
                                                                <tr>
                                                                    <th className='text-[14px] border-r border-b-[2px] border-solid relative flex justify-between items-center'>
                                                                        <span className='ml-[6px] w-1/2'>Invalid/Missing Code</span>
                                                                        <span className='flex min-h-[31px]'>
                                                                            <input
                                                                                type='text'
                                                                                id='code'
                                                                                className='pl-2 !w-[80px] outline-none placeholder:text-[12px]'
                                                                                name='codeAll'
                                                                                placeholder='Enter code'
                                                                                onChange={(e) => setCode(e.target.value)}
                                                                            />
                                                                            <div
                                                                                className='float-right w-[30px] h-[30px] flex items-center justify-center cursor-pointer px-1 py-1 text-[#FFFFFF] font-[500] text-[15 px] rounded'
                                                                                onClick={() => { setIsDialogLoading(true); checkCSVData('code'); }}
                                                                                data-tooltip-id='addCode'
                                                                            >
                                                                                <BsPlusLg className='text-[#36454F] text-lg hover:text-[#0c090ae2]' />
                                                                            </div>
                                                                            <div
                                                                                className='float-right w-[30px] h-[30px] flex items-center justify-center cursor-pointer px-1 py-1 text-[#FFFFFF] font-[500] text-[15 px] rounded'
                                                                                onClick={() => { setIsDialogLoading(true); checkCSVData('deleteAllInvalidCode'); }}
                                                                                data-tooltip-id='removeAllCode'
                                                                            >
                                                                                <MdDelete className='text-[#36454F] text-lg hover:text-[#0c090ae2]' />
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                </tr>
                                                                <ReactTooltip id="addCode" content="Add to All" className='!bg-[#121c2d]' />
                                                                <ReactTooltip id="removeAllCode" content="Remove All" className='!bg-[#121c2d]' />
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                                {
                                                                    invalidCodeNumbers?.map((number, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className='border-r py-1 px-2'>
                                                                                    <span className='flex items-center justify-between'>
                                                                                        {
                                                                                            editNumIndex === index ? (
                                                                                                <>
                                                                                                    <input
                                                                                                        type='text'
                                                                                                        id='editCode'
                                                                                                        name='editCode'
                                                                                                        className='outline-none border border-slate-600 rounded-md px-1 max-w-[160px]'
                                                                                                        value={editCode?.length ? editCode : number}
                                                                                                        ref={inputEl}
                                                                                                        onChange={(e) => setEditCode(e.target.value)}
                                                                                                    />
                                                                                                    <span>
                                                                                                        <MdClose className='hover:text-[#0c090ae2] mr-2 inline cursor-pointer text-[#9d2929]' onClick={() => { setEditNumIndex(null); setEditCode(''); }} />
                                                                                                        <MdCheck className='hover:text-[#0c090ae2] inline cursor-pointer text-[#299d3c]' onClick={() => { checkCSVData('invalidCode', number); setEditNumIndex(null); }} />
                                                                                                    </span>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {number}
                                                                                                    <div>
                                                                                                        <MdEdit className='hover:text-[#0c090ae2] mr-1 inline cursor-pointer text-[#36454F]' onClick={() => setEditNumIndex(index)} />
                                                                                                        <MdDelete className='hover:text-[#0c090ae2] inline cursor-pointer text-[#36454F]' onClick={() => checkCSVData('deleteInvalidCode', number)} />
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )
                                                }
                                                {
                                                    invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0 && (
                                                        <div className='py-1 px-2'>No invalid numbers found</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='overflow-auto flex flex-col justify-center'>
                                    <div className='overflow-x-auto w-full rounded-[8px] bg-white p-3 sm:px-5'>
                                        <div className='border border-[#eee]'>
                                            <div
                                                className='cursor-pointer bg-green-600 text-white text-[14px] relative py-1.5 px-2 font-[600] flex items-center justify-between'
                                                onClick={() => handleAccordion('valid')}
                                            >
                                                Valid Numbers
                                                <FaChevronDown color='#fff' size={"18px"} className={`mr-1 transition-all ease-in duration-75 ${activeAccordion.validSection ? 'rotate-180': ''}`} />
                                            </div>
                                            <div className={`max-h-[335px] overflow-y-scroll ${activeAccordion.validSection ? "block" : 'hidden'}`}>
                                                <table className="table-auto mx-auto w-full border">
                                                    <thead>
                                                        <tr></tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {
                                                            validNumbers?.length ?
                                                                validNumbers?.map((number, index) => (
                                                                    <tr key={index}>
                                                                        <td className='border-r py-1 px-2'>{number}</td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td className='py-1 px-2'>No valid numbers found</td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogBody>
                            <div className='bg-[#e9e9e9] p-2'>
                                <div className='flex justify-between'>
                                    <div
                                        className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        <button className='font-[300] text-[15px] text-[#000]'>Cancel</button>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div
                                            className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                            onClick={() => handleDialogNext(5)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Back</button>
                                        </div>
                                        <div
                                            className={`border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 ${invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0 && validNumbers?.length <= 0 ? "cursor-not-allowed" : "bg-gray-100 hover:bg-gray-200 shadow-lg cursor-pointer"}`}
                                            onClick={invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0 && validNumbers?.length <= 0 ? null : handleFinish}
                                        >
                                            <button className={`font-[300] text-[15px] select-none text-[#000] ${(invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0 && validNumbers?.length <= 0) ? "cursor-not-allowed text-gray-500" : ""}`}>
                                                {
                                                    invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0 ? 'Done' : 'Skip & Continue'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-2'>
                            <span className='text-[14px]'>In the List tool, you can see how many numbers are invalid or valid in the file.</span>
                        </div>
                    </div>
                )}

                {createDialogPage === 2 && dialogData.type === 'create' && (
                    <div>
                        <div className='border-[5px] border-[#2e46f36b]'>
                            <div>
                                <Dialog.Title as="h3" className="text-base text-[19px] text-left p-[14px_3px_14px_10px] shadow-md font-[400] capitalize leading-6 text-slate-800 bg-[#e9e9e9]">
                                    Name and Source
                                </Dialog.Title>
                            </div>
                            <DialogBody isDialogLoading={isDialogLoading} className='p-3 flex items-center justify-center'>
                                <div className='text-left max-w-[300px]'>
                                    <div className='mt-[10px]'>
                                        <div>
                                            <input
                                                type='text'
                                                id='name'
                                                name='csv_name'
                                                placeholder='Enter csv name'
                                                value={createDialogData.csv_name}
                                                onChange={handleCreateDialogChange}
                                                className='w-full p-1 border min-h-[35px] outline-none border-gray-300 rounded'
                                            />
                                        </div>
                                        <div className='mt-3'>
                                            <select
                                                id="csvFormat"
                                                name="format"
                                                className="w-full outline-none p-1 min-h-[35px] border border-gray-300 rounded"
                                            >
                                                <option value="">.csv</option>
                                            </select>
                                        </div>
                                        <div className='mt-3'>
                                            <input
                                                type='text'
                                                id='headerName'
                                                name='header_name'
                                                placeholder='Enter header name'
                                                value={createDialogData.header_name}
                                                onChange={handleCreateDialogChange}
                                                className='w-full p-1 border min-h-[35px] border-gray-300 outline-none rounded'
                                            />
                                            <p className="text-sm text-gray-500 mt-1">
                                                Enter header name for phone Number column
                                            </p>
                                        </div>
                                        <div className='mt-3'>
                                            <input
                                                type='checkbox'
                                                id='isPlaceholder'
                                                name='is_placeholder'
                                                checked={createDialogData.is_placeholder}
                                                onChange={e => setCreateDialogData({ ...createDialogData, 'is_placeholder': e.target.checked })}
                                                className='cursor-pointer mr-1 outline-none accent-gray-600'
                                            />
                                            <label htmlFor='isPlaceholder' className='ml-1 mr-5 text-slate-700 cursor-pointer'>More than one column</label>
                                        </div>
                                        {
                                            createDialogData.is_placeholder && (
                                                <div className='mt-3'>
                                                    <input
                                                        type='number'
                                                        id='placeholder'
                                                        name='placeholder'
                                                        placeholder='Enter number of columns'
                                                        value={createDialogData?.placeholder}
                                                        onChange={handleCreateDialogChange}
                                                        className='w-full p-1 border border-gray-300 min-h-[35px] outline-none rounded'
                                                    />
                                                    <p className="text-sm text-gray-500 mt-1">
                                                        How many columns(placeholders) do you want to add except the phone number column?
                                                    </p>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </DialogBody>
                            <div className='bg-[#e9e9e9] p-2'>
                                <div className='flex justify-between'>
                                    <div
                                        className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        <button className='font-[300] text-[15px] text-[#000]'>Cancel</button>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div
                                            className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                            onClick={() => handleDialogNext(1)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Back</button>
                                        </div>
                                        <div
                                            className={`border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 ${createDialogData.csv_name && validName() && createDialogData.header_name && (createDialogData.is_placeholder ? createDialogData.placeholder : !createDialogData.is_placeholder) ? "bg-gray-100 hover:bg-gray-200 shadow-lg cursor-pointer" : "cursor-not-allowed"}`}
                                            onClick={createDialogData.csv_name && validName() && createDialogData.header_name && (createDialogData.is_placeholder ? createDialogData.placeholder : !createDialogData.is_placeholder) ? () => handleDialogNext(3) : null}
                                        >
                                            <button className={`font-[300] text-[15px] select-none text-[#000] ${!(createDialogData.csv_name && validName() && createDialogData.header_name && (createDialogData.is_placeholder ? createDialogData.placeholder : !createDialogData.is_placeholder)) ? "cursor-not-allowed text-gray-500" : ""}`}>
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-2'>
                            <span className='text-[14px]'>Name and Source dialog is where you specify name of the csv and specify its sources</span>
                        </div>
                    </div>
                )}

                {createDialogPage === 3 && dialogData.type === 'create' && (
                    <div>
                        <div className='border-[5px] border-[#2e46f36b]'>
                            <div>
                                <Dialog.Title as="h3" className="text-base text-[19px] text-left p-[14px_3px_14px_10px] shadow-md font-[400] capitalize leading-6 text-slate-800 bg-[#e9e9e9]">
                                    CSV Data
                                </Dialog.Title>
                            </div>
                            <DialogBody isDialogLoading={isDialogLoading} className='flex flex-col'>
                                <>
                                    <div className='overflow-x-auto z-[1] overflow-auto w-full rounded-[8px] bg-white my-5 px-5'>
                                        <table className='mx-auto'>
                                            <thead className="bg-gray-100">
                                                <tr>
                                                    <th className='p-2 min-w-[50px] text-center border text-gray-500'></th>
                                                    <th className='p-2 border'>{createDialogData.header_name}</th>
                                                    {
                                                        Array.from({ length: createDialogData.placeholder }, (_, index) => (
                                                            <th key={index} className='p-2 border'>
                                                                <input
                                                                    type='text'
                                                                    placeholder='Enter header'
                                                                    name={index + 1}
                                                                    value={addHeaders[index + 1]}
                                                                    onChange={handleHeaders}
                                                                    className='w-full outline-none bg-gray-100'
                                                                />
                                                            </th>
                                                        ))
                                                    }
                                                    <th className='border text-center'>#</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    Array.from({ length: tdCount }, (_, trIndex) => (
                                                        <tr key={trIndex}>
                                                            {
                                                                tdCount - 1 === trIndex ?
                                                                    <td
                                                                        key={trIndex}
                                                                        onClick={() => setTDCount(tdCount + 1)}
                                                                        className='p-2 text-center border text-xl cursor-pointer'
                                                                    >
                                                                        <BsPlusLg className='mx-auto' data-tooltip-id="plus" />
                                                                    </td> :
                                                                    <td className='border-r'></td>
                                                            }
                                                            {
                                                                Array.from({ length: (createDialogData.placeholder ? +createDialogData.placeholder + 1 : 1) }, (_, index) => (
                                                                    <td key={index} className={`p-2 border-r border-b`}>
                                                                        <input
                                                                            type='text'
                                                                            placeholder='Enter data'
                                                                            name={index}
                                                                            value={addedData?.[trIndex]?.[index]}
                                                                            onChange={(e) => handleAddedData(trIndex, index, e.target.value)}
                                                                            autoFocus={index === 0 ? true : false}
                                                                            className='w-full outline-none'
                                                                        />
                                                                    </td>
                                                                ))
                                                            }
                                                            <td
                                                                className='p-2 w-[50px] text-center border-r border-b text-xl cursor-pointer'
                                                                onClick={() => handleRemove(trIndex)}
                                                            >
                                                                <BiMinus className='mx-auto' data-tooltip-id="minus" />
                                                            </td>
                                                            <ReactTooltip
                                                                id="minus"
                                                                content="Remove row"
                                                                className='!bg-[#121c2d]'
                                                            />
                                                            <ReactTooltip
                                                                id="plus"
                                                                content="Add row"
                                                                className='!bg-[#121c2d]'
                                                            />
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                            </DialogBody>
                            <div className='bg-[#e9e9e9] p-2'>
                                <div className='flex justify-between'>
                                    <div
                                        className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        <button className='font-[300] text-[15px] text-[#000]'>Cancel</button>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div
                                            className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                            onClick={() => handleDialogNext(2)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Back</button>
                                        </div>
                                        <div
                                            className={`border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 ${validateCreatePage3() ? "bg-gray-100 hover:bg-gray-200 shadow-lg cursor-pointer" : "cursor-not-allowed"}`}
                                            onClick={validateCreatePage3() ? () => handleDialogNext(4) : null}
                                        >
                                            <button className={`font-[300] text-[15px] select-none text-[#000] ${!validateCreatePage3() ? "cursor-not-allowed text-gray-500" : ""}`}>
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-2'>
                            <span className='text-[14px]'>CSV data dialog is where you add phone numbers or other columns(placeholders) to the CSV</span>
                        </div>
                    </div>
                )}

                {createDialogPage === 4 && dialogData.type === 'create' && (
                    <div>
                        <div className='border-[5px] border-[#2e46f36b]'>
                            <div>
                                <Dialog.Title as="h3" className="text-base text-[19px] text-left p-[14px_3px_14px_10px] shadow-md font-[400] capitalize leading-6 text-slate-800 bg-[#e9e9e9]">
                                    Final CSV
                                </Dialog.Title>
                            </div>
                            <DialogBody isDialogLoading={isDialogLoading} className='flex flex-col justify-center'>
                                {
                                    addedData && addHeaderList &&
                                    <>
                                        <div className='overflow-x-auto w-full rounded-[8px] bg-white my-5 px-3 sm:px-5'>
                                            <table className="table-auto mx-auto w-full border">
                                                <thead className="bg-gray-100">
                                                    <tr>
                                                        {
                                                            addHeaderList?.length && addHeaderList.map((header) => (
                                                                <th className='text-[rgba(18,28,45,0.89)] text-[14px] border border-solid relative p-2 h-[22px]'>
                                                                    {header}
                                                                </th>
                                                            ))
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody className="divide-y divide-gray-200 bg-white">
                                                    {
                                                        addedData && Object.values(addedData)?.map((row, index) => (
                                                            <tr key={index}>
                                                                {
                                                                    row && row?.map((data) => (
                                                                        <td key={index} className='py-1 px-2 border-r'>{data}</td>
                                                                    ))
                                                                }
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </>
                                }
                            </DialogBody>
                            <div className='bg-[#e9e9e9] p-2'>
                                <div className='flex justify-between'>
                                    <div
                                        className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        <button className='font-[300] text-[15px] text-[#000]'>Cancel</button>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div
                                            className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                            onClick={() => handleDialogNext(3)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Back</button>
                                        </div>
                                        <div
                                            className={`border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer`}
                                            onClick={() => handleDialogNext(5)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Next</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-2'>
                            <span className='text-[14px]'>CSV output with added data</span>
                        </div>
                    </div>
                )}

                {createDialogPage === 5 && dialogData.type === 'create' && (
                    <div>
                        <div className='border-[5px] border-[#2e46f36b]'>
                            <div>
                                <Dialog.Title as="h3" className="text-base text-[19px] text-left p-[14px_3px_14px_10px] shadow-md font-[400] capitalize leading-6 text-slate-800 bg-[#e9e9e9]">
                                    List Tool
                                </Dialog.Title>
                            </div>
                            <DialogBody isDialogLoading={isDialogLoading}>
                                <div className='overflow-auto flex flex-col justify-center'>
                                    <div className='w-full rounded-[8px] bg-white p-3 sm:px-5'>
                                        <div className='border border-[#eee]'>
                                            <div
                                                className='bg-red-600 text-white text-[14px] relative py-1.5 px-2 font-[600] flex justify-between items-center cursor-pointer'
                                                onClick={() => handleAccordion('invalid')}
                                            >
                                                Invalid Numbers
                                                <FaChevronDown color='#fff' size={"18px"} className={`mr-1 transition-all ease-in duration-75 ${activeAccordion.invalidSection ? 'rotate-180' : ''}`} />
                                            </div>
                                            <div className={`${activeAccordion.invalidSection ? 'grid' : 'hidden'} p-2 overflow-auto max-h-[347px] grid grid-cols-1 sm:grid-cols-2 items-start`}>
                                                {
                                                    invalidNumbers?.length > 0 && (
                                                        <table className="table-auto mx-auto w-full border mb-2">
                                                            <thead className='bg-gray-100'>
                                                                <tr>
                                                                    <th className='text-[14px] border-r border-b-[2px] border-solid relative py-1.5 pl-2 h-[22px]'>
                                                                        Invalid Format/Numbers
                                                                        <div
                                                                            className='float-right flex items-center justify-center cursor-pointer pr-2 text-[#FFFFFF] font-[500] text-[15 px] rounded'
                                                                            onClick={() => { setIsDialogLoading(true); checkCreateCSV('deleteAllInvalidNumbers'); }}
                                                                            data-tooltip-id='createInvalidNumbers'
                                                                        >
                                                                            <MdDelete className='text-[#36454F] text-lg hover:text-[#0c090ae2]' />
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                                <ReactTooltip id="createInvalidNumbers" content="Remove All" className='!bg-[#121c2d]' />
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                                {
                                                                    invalidNumbers?.map((number, index) => (
                                                                        <tr key={index}>
                                                                            <td className='border-r py-1 px-2'>
                                                                                <span className='flex items-center justify-between'>
                                                                                    {
                                                                                        numIndex === index ? (
                                                                                            <>
                                                                                                <input
                                                                                                    type='text'
                                                                                                    id='newNumber'
                                                                                                    name='newNumber'
                                                                                                    className='outline-none border border-slate-600 rounded-md px-1 max-w-[160px]'
                                                                                                    value={newNumber?.length ? newNumber : number}
                                                                                                    ref={inputEl}
                                                                                                    onChange={(e) => setNewNumber(e.target.value)}
                                                                                                />
                                                                                                <span>
                                                                                                    <MdClose className='hover:text-[#0c090ae2] mr-2 inline cursor-pointer text-[#9d2929]' onClick={() => { setNumIndex(null); setNewNumber(''); }} />
                                                                                                    <MdCheck className='hover:text-[#0c090ae2] inline cursor-pointer text-[#299d3c]' onClick={() => { checkCreateCSV('invalidNumber', number); setNumIndex(null); }} />
                                                                                                </span>
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {number}
                                                                                                <div>
                                                                                                    <MdEdit className='hover:text-[#0c090ae2] mr-1 inline cursor-pointer text-[#36454F]' onClick={() => setNumIndex(index)} />
                                                                                                    <MdDelete className='hover:text-[#0c090ae2] inline cursor-pointer text-[#36454F]' onClick={() => checkCreateCSV('deleteInvalidNumber', number)} />
                                                                                                </div>
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )
                                                }
                                                {
                                                    whiteSpaceNumbers?.length > 0 && (
                                                        <table className="table-auto mx-auto w-full border mb-2">
                                                            <thead className='bg-gray-100'>
                                                                <tr>
                                                                    <th className='text-[14px] border-r border-b-[2px] border-solid relative flex justify-between items-center'>
                                                                        <span className='ml-[6px]'>Whitespaces</span>
                                                                        <div
                                                                            className='float-right px-1 py-1 mr-[6px] text-orange-500 hover:text-orange-600 underline font-[500] text-[15px] cursor-pointer rounded'
                                                                            onClick={() => { setIsDialogLoading(true); checkCreateCSV('whitespace'); }}
                                                                        >
                                                                            Remove Whitespaces
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                                {
                                                                    whiteSpaceNumbers?.map((number, index) => (
                                                                        <tr key={index}>
                                                                            <td className='border-r py-1 px-2'>{number}</td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )
                                                }
                                                {
                                                    invalidCodeNumbers?.length > 0 && (
                                                        <table className={`table-auto mx-auto w-full border mb-2 ${whiteSpaceNumbers?.length > 0 && invalidNumbers?.length > 0 ? "col-span-1 sm:col-span-2" : ""} `}>
                                                            <thead className='bg-gray-100'>
                                                                <tr>
                                                                    <th className='text-[14px] border-r border-b-[2px] border-solid relative flex justify-between items-center'>
                                                                        <span className='ml-[6px] w-1/2'>Invalid/Missing Code</span>
                                                                        <span className='flex min-h-[31px]'>
                                                                            <input
                                                                                type='text'
                                                                                id='code'
                                                                                className='pl-2 !w-[80px] outline-none placeholder:text-[12px]'
                                                                                name='codeAll'
                                                                                placeholder='Enter code'
                                                                                onChange={(e) => setCode(e.target.value)}
                                                                            />
                                                                            <div
                                                                                className='float-right w-[30px] h-[30px] flex items-center justify-center cursor-pointer px-1 py-1 text-[#FFFFFF] font-[500] text-[15 px] rounded'
                                                                                onClick={() => { setIsDialogLoading(true); checkCreateCSV('code'); }}
                                                                                data-tooltip-id='createAddCode'
                                                                            >
                                                                                <BsPlusLg className='text-[#36454F] text-lg hover:text-[#0c090ae2]' />
                                                                            </div>
                                                                            <div
                                                                                className='float-right w-[30px] h-[30px] flex items-center justify-center cursor-pointer px-1 py-1 text-[#FFFFFF] font-[500] text-[15 px] rounded'
                                                                                onClick={() => { setIsDialogLoading(true); checkCreateCSV('deleteAllInvalidCode'); }}
                                                                                data-tooltip-id='createRemoveAllCode'
                                                                            >
                                                                                <MdDelete className='text-[#36454F] text-lg hover:text-[#0c090ae2]' />
                                                                            </div>
                                                                        </span>
                                                                    </th>
                                                                </tr>
                                                                <ReactTooltip id="createAddCode" content="Add to All" className='!bg-[#121c2d]' />
                                                                <ReactTooltip id="createRemoveAllCode" content="Remove All" className='!bg-[#121c2d]' />
                                                            </thead>
                                                            <tbody className="divide-y divide-gray-200 bg-white">
                                                                {
                                                                    invalidCodeNumbers?.map((number, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td className='border-r py-1 px-2'>
                                                                                    <span className='flex items-center justify-between'>
                                                                                        {
                                                                                            editNumIndex === index ? (
                                                                                                <>
                                                                                                    <input
                                                                                                        type='text'
                                                                                                        id='editCode'
                                                                                                        name='editCode'
                                                                                                        className='outline-none border border-slate-600 rounded-md px-1 max-w-[160px]'
                                                                                                        value={editCode?.length ? editCode : number}
                                                                                                        ref={inputEl}
                                                                                                        onChange={(e) => setEditCode(e.target.value)}
                                                                                                    />
                                                                                                    <span>
                                                                                                        <MdClose className='hover:text-[#0c090ae2] mr-2 inline cursor-pointer text-[#9d2929]' onClick={() => { setEditNumIndex(null); setEditCode(''); }} />
                                                                                                        <MdCheck className='hover:text-[#0c090ae2] inline cursor-pointer text-[#299d3c]' onClick={() => { checkCreateCSV('invalidCode', number); setEditNumIndex(null); }} />
                                                                                                    </span>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {number}
                                                                                                    <div>
                                                                                                        <MdEdit className='hover:text-[#0c090ae2] mr-1 inline cursor-pointer text-[#36454F]' onClick={() => setEditNumIndex(index)} />
                                                                                                        <MdDelete className='hover:text-[#0c090ae2] inline cursor-pointer text-[#36454F]' onClick={() => checkCreateCSV('deleteInvalidCode', number)} />
                                                                                                    </div>
                                                                                                </>
                                                                                            )
                                                                                        }
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    )
                                                }
                                                {
                                                    invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0 && (
                                                        <div className='py-1 px-2'>No invalid numbers found</div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='overflow-auto flex flex-col justify-center'>
                                    <div className='overflow-x-auto w-full rounded-[8px] bg-white p-3 sm:px-5'>
                                        <div className='border border-[#eee]'>
                                            <div
                                                className='cursor-pointer bg-green-600 text-white text-[14px] relative py-1.5 px-2 font-[600] flex items-center justify-between'
                                                onClick={() => handleAccordion('valid')}
                                            >
                                                Valid Numbers
                                                <FaChevronDown color='#fff' size={"18px"} className={`mr-1 transition-all ease-in duration-75 ${activeAccordion.validSection ? 'rotate-180' : ''}`} />
                                            </div>
                                            <div className={`max-h-[335px] overflow-y-scroll ${activeAccordion.validSection ? "block" : 'hidden'}`}>
                                                <table className="table-auto mx-auto w-full border">
                                                    <thead>
                                                        <tr></tr>
                                                    </thead>
                                                    <tbody className="divide-y divide-gray-200 bg-white">
                                                        {
                                                            validNumbers?.length ?
                                                                validNumbers?.map((number, index) => (
                                                                    <tr key={index}>
                                                                        <td className='border-r py-1 px-2'>{number}</td>
                                                                    </tr>
                                                                ))
                                                                :
                                                                <tr>
                                                                    <td className='py-1 px-2'>No valid numbers found</td>
                                                                </tr>
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </DialogBody>
                            <div className='bg-[#e9e9e9] p-2'>
                                <div className='flex justify-between'>
                                    <div
                                        className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                        onClick={handleCancel}
                                    >
                                        <button className='font-[300] text-[15px] text-[#000]'>Cancel</button>
                                    </div>
                                    <div className='flex gap-2'>
                                        <div
                                            className='border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 bg-gray-100 hover:bg-gray-200 select-none cursor-pointer'
                                            onClick={() => handleDialogNext(4)}
                                        >
                                            <button className='font-[300] text-[15px] text-[#000]'>Back</button>
                                        </div>
                                        <div
                                            className={`border px-4 py-2 h-[30px] text-center flex items-center shadow-lg border-gray-300 ${invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0 && validNumbers?.length <= 0 ? "cursor-not-allowed" : "bg-gray-100 hover:bg-gray-200 shadow-lg cursor-pointer"}`}
                                            onClick={invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0 && validNumbers?.length <= 0 ? null : handleCreate}
                                        >
                                            <button className={`font-[300] text-[15px] select-none text-[#000] ${(invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0 && validNumbers?.length <= 0) ? "cursor-not-allowed text-gray-500" : ""}`}>
                                                {
                                                    invalidNumbers?.length <= 0 && whiteSpaceNumbers?.length <= 0 && invalidCodeNumbers?.length <= 0 ? 'Done' : 'Skip & Continue'
                                                }
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='flex p-2'>
                            <span className='text-[14px]'>In the List tool, you can see how many numbers are invalid or valid in the file.</span>
                        </div>
                    </div>
                )}
            </Popup>

            <div className='flex p-4 flex-col justify-center max-w-md w-full'>
                <div className='flex items-center mb-[20px]'>
                    <div
                        className='w-[30px] h-[30px] sm:w-[35px] sm:h-[35px] border cursor-pointer hover:bg-gray-800 group border-gray-800 rounded-full grid place-items-center'
                        onClick={() => navigate('/scheduled-sms')}
                    >
                        <BiArrowBack
                            size={'20px'}
                            className='group-hover:text-white'
                        />
                    </div>
                    <h1 className='text-center flex-grow text-[20px] sm:text-[22px] md:text-[25px] capitalize'>update schedule message</h1>
                </div>
                <div className="bg-white p-4 sm:p-8 rounded-lg shadow-md w-full max-w-md">
                    <form onSubmit={handleSubmit} className="space-y-4">
                        <div>
                            <div
                                className='block text-[14px] sm:text-[16px] w-full p-2.5 sm:p-3 border border-gray-300 rounded text-center hover:bg-[#EFEFEF] hover:text-[#000] transition-all hover:shadow-lg cursor-pointer'
                                onClick={handleOpen}
                            >
                                {file ? file : formData.file_name ? formData.file_name : 'Upload CSV'}
                            </div>
                        </div>

                        <div>
                            <select
                                id="fromNumber"
                                name="from_number"
                                value={formData.from_number}
                                onChange={handleChange}
                                className="block w-full text-[14px] sm:text-[16px] p-3 border border-gray-300 rounded outline-none"
                            >
                                <option value="">Select a from number</option>
                                {fromNumbers && fromNumbers.map((number) => (
                                    <option key={number} value={number}>
                                        {number}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div>
                            <textarea
                                id="message"
                                name="message_body"
                                value={formData.message_body}
                                onChange={handleChange}
                                className="block w-full text-[14px] sm:text-[16px] p-3 border border-gray-300 rounded outline-none"
                                placeholder="Enter your message"
                                rows="4"
                            />
                            <div className='flex'>
                                <BsInfoCircleFill
                                    className='text-blue-600 m-[0.2rem] cursor-pointer'
                                    data-tooltip-id="info-placeholder"
                                />
                                <p className={`text-[12px] sm:text-sm ${charCountExceeded ? 'text-red-600' : 'text-gray-500'}`}>
                                    Characters: {charCount} / 160, Segments: {segments}
                                </p>
                            </div>
                        </div>
                        <ReactTooltip
                            id="info-placeholder"
                            content="You can use it in a message by writing the CSV column name(variable) in {}, eg:- Hello {columnName}"
                            className='!bg-[#121c2d] !text-sm !text-center !w-96 !block'
                        />

                        <div>
                            <label htmlFor="isMedia" className="mb-[5px] text-[14px] sm:text-[16px] flex items-center">
                                <input
                                    id="isMedia"
                                    name="isMedia"
                                    type="checkbox"
                                    checked={isMedia}
                                    onChange={(e) => {
                                        if (e.target.checked === true) {
                                            setIsMedia(e.target.checked);
                                        } else {
                                            setIsMedia(e.target.checked); setFormData({ ...formData, image: undefined, image_url: '' })
                                        }
                                    }}
                                    className="mr-2 outline-none cursor-pointer"
                                />
                                Do you want to attach an Image?
                            </label>
                        </div>

                        {
                            isMedia && (
                                <div>
                                    <div className='flex items-center mb-1 sm:mb-2 border p-[6px] rounded-[0.25rem]'>
                                        <label htmlFor='image' className='block'>
                                            <span className={`block mr-3 text-[14px] sm:text-[16px] rounded-[0.25rem] bg-slate-200 ${!formData.image_url && 'hover:bg-slate-300 cursor-pointer'} border border-slate-200 bg-opacity-50 p-[6px_10px]`}>
                                                Upload
                                            </span>
                                        </label>
                                        <div
                                            className='block flex-grow overflow-hidden text-ellipsis whitespace-pre'
                                        >
                                            {
                                                image ? image : <span className='text-[14px] sm:text-[16px]'>No file chosen</span>
                                            }
                                        </div>
                                    </div>
                                    <input
                                        id="image"
                                        name="image"
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => {
                                            if (e.target.files[0] && e.target.files[0].size <= 2 * 1024 * 1024) {
                                                setFormData({ ...formData, 'image': e.target.files[0], 'image_url': '' });
                                                setImage(e.target.files[0]?.name);
                                            } else {
                                                e.target.value = null;
                                                setFormData({ ...formData, image: '' });
                                                setImage();
                                            }
                                        }}
                                        className="hidden w-full text-[14px] sm:text-[16px] p-3 border border-gray-300 rounded outline-none"
                                        disabled={formData.image_url}
                                    />
                                    <p className="text-[12px] sm:text-[14px] text-gray-500">
                                        Supported format .jpg/.png, Max file size 2MB
                                    </p>

                                    <p className='text-center'>OR</p>

                                    <input
                                        type='text'
                                        id='url'
                                        name='image_url'
                                        placeholder='Enter Image url'
                                        value={formData.image_url}
                                        onChange={(e) => { setFormData({ ...formData, image_url: e.target.value, image: '' }); setImage(''); }}
                                        className='w-full border text-[14px] sm:text-[16px] p-3 min-h-[45px] outline-none border-gray-300 rounded'
                                        disabled={formData.image}
                                    />
                                </div>
                            )
                        }

                        <div>
                            <label htmlFor="isASAP" className="mb-[5px] text-[14px] sm:text-[16px] capitalize flex items-center ">
                                <input
                                    id="isASAP"
                                    name="is_send_asap"
                                    type="checkbox"
                                    checked={formData.is_send_asap}
                                    onChange={handleIsASAP}
                                    className="mr-2"
                                />
                                Send ASAP
                                <BsInfoCircleFill
                                    className='text-blue-600 ml-1 cursor-pointer'
                                    data-tooltip-id="info"
                                />
                            </label>
                            <ReactTooltip
                                id="info"
                                content="Send messages ASAP(as soon as possible) without scheduling."
                                className='!bg-[#121c2d] !w-60 !text-sm !text-center'
                            />
                        </div>

                        {
                            !formData.is_send_asap && (
                                <>
                                    <div>
                                        <input
                                            id="date"
                                            name="date"
                                            type="date"
                                            value={formData.date}
                                            onChange={handleChange}
                                            className="block w-full text-[14px] sm:text-[16px] p-3 border border-gray-300 rounded mb-2 outline-none"
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="time" className={`${!isValidTime ? 'text-red-600' : ''} mb-1 block`}>
                                            Select a time between 6 AM to 8 PM
                                        </label>
                                        <input
                                            id="time"
                                            name="time"
                                            type="time"
                                            value={formData.time}
                                            onChange={(e) => { handleChange(e); handleTime(e.target.value) }}
                                            className={`block w-full text-[14px] sm:text-[16px] p-3 border ${isValidTime ? 'border-gray-300' : 'border-red-600'} rounded outline-none`}
                                        />
                                        <p className="text-[12px] sm:text-[14px] text-gray-500">
                                            Time will be processed in Atlantic Standard Time
                                        </p>
                                    </div>
                                </>
                            )
                        }

                        <button
                            type="submit"
                            className={`w-full p-3 ${(file || formData.file || formData.file_name) && formData.from_number && formData.message_body && ((formData.date && formData.time && isValidTime) || formData.is_send_asap) && (isMedia ? (image || formData.image_url) : !isMedia)
                                ? "bg-blue-600"
                                : "bg-gray-400"
                                } text-white font-semibold rounded`}
                            disabled={
                                !((file || formData.file || formData.file_name) && formData.from_number && formData.message_body && ((formData.date && formData.time && isValidTime) || formData.is_send_asap) && (isMedia ? (image || formData.image_url) : !isMedia))
                            }
                        >
                            Update
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
};

export default EditScheduleSmsPage;
