import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import { useAuth } from '../components/context/AuthContext';
import Loader from '../components/Loader';
import ResponseModal from '../components/ResponseModal';
import { FaCheck, FaRegCopy } from 'react-icons/fa';

const Link = () => {
    const navigate = useNavigate();
    const { accessToken, handleSignOut } = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [originalLink, setOriginalLink] = useState('');
    const [newLink, setNewLink] = useState('');
    const [copied, setCopied] = useState(false);

    const commonHeader = {
        'content-type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    useEffect(() => {
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }, [copied])


    const handleSubmit = (e) => {
        e.preventDefault();

        setIsLoading(true);
        const url = `${BACKEND_BASE_URL}/api/link/`
        const data = {
            'original_url': originalLink
        };
        axios.post(url, data, { headers: commonHeader })
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    setNewLink(response.data?.url);
                    setOriginalLink('');
                };
            }).catch((error) => {
                setIsLoading(false);
                setApiResponse(error.response.data);
                console.log("error", error);
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
    };

    const handleLink = () => {
        const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

        if (originalLink) {
            if (urlRegex.test(originalLink)) {
                return true;
            } else {
                return false;
            };
        } else {
            return false;
        };
    };

    return (
        <div className="min-h-[calc(100vh_-_64px_-_128px)] bg-gray-100 flex justify-center py-5">
            {
                isLoading && <Loader />
            }
            {apiResponse && (
                <ResponseModal
                    apiResponse={apiResponse}
                    onClick={() => { setIsLoading(false); setApiResponse(null); }}
                />
            )}
            <div className='flex p-4 flex-col justify-center max-w-xl w-full'>
                <div className='flex items-center mb-[20px]'>
                    <div
                        className='w-[30px] h-[30px] sm:w-[35px] sm:h-[35px] border cursor-pointer hover:bg-gray-800 group border-gray-800 rounded-full grid place-items-center'
                        onClick={newLink && newLink?.length ? () => { setNewLink(''); setCopied(false); } : () => navigate('/schedule-messages')}
                    >
                        <BiArrowBack
                            size={'20px'}
                            className='group-hover:text-white'
                        />
                    </div>
                    <h1 className='text-center flex-grow text-[20px] sm:text-[22px] md:text-[25px] capitalize'>
                        {newLink && newLink?.length ? 'Back to Link Shortening' : 'Link Shortening'}
                    </h1>
                </div>
                <div className="bg-white p-4 sm:p-8 rounded-lg shadow-md w-full max-w-xl">
                    {
                        newLink && newLink?.length ?
                            <div className='flex items-center justify-between'>
                                <div>{newLink}</div>
                                <button type="submit" onClick={() => { navigator.clipboard.writeText(newLink); setCopied(true); }}>
                                    {
                                        copied ? <FaCheck size={'22px'} color='green' /> : <FaRegCopy size={'22px'} />
                                    }
                                </button>
                            </div>
                            :
                            <form onSubmit={handleSubmit} className="space-y-4">
                                <div>
                                    <textarea
                                        id="link"
                                        name="link"
                                        placeholder='Enter your link'
                                        value={originalLink}
                                        onChange={(e) => setOriginalLink(e.target.value)}
                                        className="block w-full p-3 border border-gray-300 rounded outline-none"
                                        rows="3"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className={`w-full p-3 ${handleLink() ? "bg-blue-600" : "bg-gray-400"} text-white font-semibold rounded`}
                                    disabled={!(handleLink())}
                                >
                                    Submit
                                </button>
                            </form>
                    }
                </div>
            </div>
        </div>
    )
};

export default Link;
