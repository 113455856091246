import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import { useAuth } from '../components/context/AuthContext';
import { BiArrowBack } from 'react-icons/bi';

const AdminDetailPage = () => {
    const params = useParams();
    const navigate = useNavigate();
    const { handleSignOut, accessToken } = useAuth();

    const [formData, setFormData] = useState(null);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    useEffect(() => {
        fetchUserDetail()
        // eslint-disable-next-line
    }, [params])

    const fetchUserDetail = () => {
        if (params && params?.id) {
            axios.get(`${BACKEND_BASE_URL}/api/admin_user/${params.id}/`, {
                headers: headers,
            }).then((response) => {
                if (response.status === 200) {
                    setFormData({
                        date_joined: response.data?.date_joined,
                        last_login: response.data?.last_login,
                        email: response.data?.email,
                        username: response.data?.username,
                        first_name: response.data?.first_name,
                        last_name: response.data?.last_name,
                        provider: response.data?.provider,
                        client_id: response.data?.client_id,
                        secret_key: response.data?.secret_key,
                        mobile_no: response.data?.mobile_no,
                        messages_sent: response.data?.messages_sent,
                        is_mobile_no_verified: response.data?.is_mobile_no_verified,
                        is_otp_verification: response.data?.is_otp_verification,
                        is_active: response.data?.is_active,
                        is_staff: response.data?.is_staff,
                        is_superuser: response.data?.is_superuser,
                        is_time_restriction: response.data?.is_time_restriction
                    });
                };
            }).catch((error) => {
                console.log("error", error)
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
        }
    };

    const handleText = (text) => {
        let textLength = text?.length;
        if (textLength > 7) {
            return (text.substring(0,3) + '*'.repeat(textLength - 7) + text.slice(-4));
        };
        return text;
    };

    return (
        <>
            <div className="min-h-[calc(100vh_-_64px_-_128px)] flex-col bg-gray-100 flex items-center justify-center py-5 px-5" >
                <div className='flex flex-col justify-center max-w-[1100px] w-full'>
                    <div className='flex items-center mb-[10px]'>
                        <div
                            className='w-[30px] h-[30px] sm:w-[35px] sm:h-[35px] border cursor-pointer hover:bg-gray-800 group border-gray-800 rounded-full grid place-items-center'
                            onClick={() => navigate('/admin')}
                        >
                            <BiArrowBack
                                size={'20px'}
                                className='group-hover:text-white'
                            />
                        </div>
                        <h1 className='text-center capitalize flex-grow text-[25px] mb-[10px]'>User's Details</h1>
                    </div>
                </div>

                <div className="bg-white p-8 rounded-lg shadow-md w-full  max-w-[1100px] max-lg:max-w-[600px]">
                    <div className='flex max-lg:flex-col gap-7'>
                        <div className='w-1/2 max-lg:w-full border-r-2 border-gray-200 max-lg:border-none pr-7 space-y-4'>
                            <div className='md:flex py-1.5 px-1 !sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>joined date:</div>
                                <div>{formData?.date_joined ? formData.date_joined : '-'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>last login:</div>
                                <div>{formData?.last_login ? formData.last_login : '-'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>email:</div>
                                <div>{formData?.email ? formData.email : '-'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>username:</div>
                                <div>{formData?.username ? formData.username : '-'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>first name:</div>
                                <div>{formData?.first_name ? formData.first_name : '-'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>last name:</div>
                                <div>{formData?.last_name ? formData.last_name : '-'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>mobile no:</div>
                                <div>{formData?.mobile_no ? `+${formData.mobile_no}` : '-'}</div>
                            </div>
                        </div>
                        <div className='w-1/2 max-lg:w-full space-y-4'>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>provider:</div>
                                <div>{formData?.provider?.name ? formData.provider.name : '-'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>client id:</div>
                                <div style={{ overflowWrap: 'anywhere' }}>{formData?.client_id ? formData.client_id : '-'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>secret key:</div>
                                <div>{formData?.secret_key ? handleText(formData.secret_key) : '-'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>sent messages:</div>
                                <div>{formData?.messages_sent}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>Active:</div>
                                <div>{formData?.is_active ? 'Yes' : 'No'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>Admin:</div>
                                <div>{formData?.is_staff ? 'Yes' : 'No'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>OTP verification:</div>
                                <div>{formData?.is_otp_verification ? 'Yes' : 'No'}</div>
                            </div>
                            <div className='md:flex py-1.5 px-1 sm:flex-wrap'>
                                <div className='capitalize text-stone-600 font-bold min-w-[145px]'>Time Restriction:</div>
                                <div>{formData?.is_time_restriction ? 'Yes' : 'No'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default AdminDetailPage;
