import React from 'react';
import { BiExit } from 'react-icons/bi';
import { MdError, MdVerified } from 'react-icons/md';

const ResponseModal = ({ apiResponse, onClick }) => {
    return (
        <div className="fixed z-10 inset-0 p-4 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen">
                <div
                    className="fixed inset-0 bg-gray-500 opacity-75"
                    aria-hidden="true"
                ></div>
                <div className="bg-white p-5 rounded-lg shadow-md w-full max-w-lg mx-auto relative">
                    <div>
                        <div className='flex flex-col'>
                            <div className='text-center'>
                                {
                                    apiResponse && apiResponse?.status === "success"
                                        ? <MdVerified className='text-green-600 inline' size={"70px"} />
                                        : <MdError className='text-red-600 inline' size={"70px"} />
                                }
                            </div>
                            <div className='text-center text-xl font-bold text-stone-700 my-5'>
                                {
                                    (apiResponse && apiResponse?.message)
                                        ? apiResponse.message : apiResponse ? apiResponse : 'An error occurred while requesting.'
                                }
                            </div>
                        </div>
                    </div>
                    <BiExit
                        className='absolute top-2 right-2 text-[indianred] cursor-pointer'
                        size={'30px'}
                        onClick={onClick}
                    />
                </div>
            </div>
        </div>
    )
};

export default ResponseModal;
