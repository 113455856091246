import React, { Fragment, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { MdAccountCircle } from 'react-icons/md'
import { Menu, Transition } from '@headlessui/react';


const Navbar = () => {

  const { isAuthenticated, handleSignOut, name, isAdmin } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [subMenu, setSubMenu] = useState(false);

  const renderNavbarContent = () => {
    let displayName = '';
    if (isAuthenticated) {
      displayName = name ? name : 'User';
    } else {
      displayName = 'stranger';
    }

    return (
      <>
        <span className="text-white hidden sm:inline-flex font-bold text-md xl:text-xl mr-4">
          Hello, {displayName}!
        </span>

        {
          isAuthenticated ?
            <button
              className="bg-red-500 mr-4 hidden sm:block hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                handleSignOut();
                navigate('/login')
              }}
            >
              Sign Out
            </button>
            :
            <></>
        }

        <Menu as="div" className="relative inline-block text-left">
          <div className=''>
            <Menu.Button>
              <MdAccountCircle size={"40px"} className='text-white inline-block' />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute z-[999999] right-0 mt-2 w-[150px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              {
                isAuthenticated ?
                  <>
                    <div className="px-1 truncate max-w-[150px] py-1 inline-flex sm:hidden">
                      <Menu.Item className="truncate">


                        <p className="text-black px-2 py-2 truncate text-sm font-bold text-md xl:text-xl ">
                          Hello, {displayName}!
                        </p>


                      </Menu.Item>
                    </div>
                    <div className="px-1 py-1 ">
                      <Menu.Item>
                        <Link to={"/profile"} className=''>
                          <button
                            className={` group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-opacity-[0.1] hover:bg-[#616161]`}
                          >
                            Profile
                          </button>
                        </Link>
                      </Menu.Item>
                    </div>
                    {
                      isAdmin && (
                        <div className="px-1 py-1 ">
                          <Menu.Item>
                            <Link to={"/admin"} className=''>
                              <button
                                className={` group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-opacity-[0.1] hover:bg-[#616161]`}
                              >
                                Admin
                              </button>
                            </Link>
                          </Menu.Item>
                        </div>
                      )
                    }
                    <div className="px-1 py-1  sm:hidden">
                      <Menu.Item>

                        <button
                          className=" group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-opacity-[0.1] hover:bg-[#616161]"
                          onClick={() => {
                            handleSignOut();
                            navigate('/login')
                          }}
                        >
                          Sign Out
                        </button>

                      </Menu.Item>
                    </div>
                  </>
                  :
                  <div className="px-1 py-1 ">
                    <Menu.Item>
                      <Link to="/login" onClick={() => navigate('/login')} className=''>
                        <button
                          className={` group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-opacity-[0.1] hover:bg-[#616161]`}
                        >
                          Login
                        </button>
                      </Link>
                    </Menu.Item>
                  </div>
              }

            </Menu.Items>
          </Transition>
        </Menu>
      </>
    );
  };

  return (
    <nav className="bg-gray-800 relative">
      <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
            <button onClick={() => setIsOpen(!isOpen)} type="button" className="inline-flex items-center justify-center  rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-0 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
              <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex items-center pl-10 lg:pl-0 justify-center md:items-stretch md:justify-start">
            <div className="flex-shrink-0">
              <Link to="/" className="text-white font-bold text-xl">SMS Services Company</Link>
            </div>
            <div className="hidden lg:block sm:ml-6">
              <div className="flex xl:space-x-4">
                <Link
                  to="/"
                  className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${location.pathname === '/' && "bg-gray-700 text-white"}`}
                >
                  Home
                </Link>
                {/* <Link
                  to="/pricing"
                  className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${location.pathname === '/pricing' && "bg-gray-700 text-white"}`}
                >
                  Pricing
                </Link> */}
                {/* <Link
                  to="/contact"
                  className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${location.pathname === '/contact' && "bg-gray-700 text-white"}`}
                >
                  Contact
                </Link> */}
                <Link
                  to="/schedule-messages"
                  className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${(location.pathname === '/schedule-messages' || location.pathname === '/link') && "bg-gray-700 text-white"}`}
                >
                  Schedule Messages
                </Link>
                <Link
                  to="/scheduled-sms"
                  className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${location.pathname === '/scheduled-sms' && "bg-gray-700 text-white"}`}
                >
                  Scheduled SMS
                </Link>
                <Menu as="div" className="relative inline-block text-left">
                  <div >
                    <Menu.Button>
                      <button
                        className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${(location.pathname === '/report' || location.pathname === '/report-summary') && "bg-gray-700 text-white"}`}
                      >
                        Status
                      </button>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 mt-2 w-[150px] origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <div className="px-1 py-1 ">
                        <Menu.Item>
                          <Link to={"/report"} className=''>
                            <button
                              className={` group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-opacity-[0.1] hover:bg-[#616161]`}
                            >
                              Report
                            </button>
                          </Link>
                        </Menu.Item>
                      </div>
                      <div className="px-1 py-1 ">
                        <Menu.Item>
                          <Link to={"/report-summary"} className=''>
                            <button
                              className={` group flex w-full items-center rounded-md px-2 py-2 text-sm hover:bg-opacity-[0.1] hover:bg-[#616161]`}
                            >
                              Report Summary
                            </button>
                          </Link>
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Link
                  to="/dashboard"
                  className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${location.pathname === '/dashboard' && "bg-gray-700 text-white"}`}
                >
                  Dashboard
                </Link>
              </div>
            </div>
          </div>
          <div className="xl:ml-6 flex items-center">
            {renderNavbarContent()}
          </div>
        </div>
      </div>
      <div className={`${isOpen ? 'block lg:hidden' : 'hidden'} absolute w-full z-[9999] bg-gray-800`} id="mobile-menu">
        <div className="px-2 pt-2 grid pb-3 space-y-1">
          <Link
            to="/"
            onClick={() => setIsOpen(false)}
            className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${location.pathname === '/' && "bg-gray-700 text-white"}`}
          >
            Home
          </Link>
          <Link
            to="/schedule-messages"
            onClick={() => setIsOpen(false)}
            className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${(location.pathname === '/schedule-messages' || location.pathname === '/link') && "bg-gray-700 text-white"}`}
          >
            Schedule Messages
          </Link>
          <Link
            to="/scheduled-sms"
            onClick={() => setIsOpen(false)}
            className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${location.pathname === '/scheduled-sms' && "bg-gray-700 text-white"}`}
          >
            Scheduled SMS
          </Link>
          <Link
            to="/dashboard"
            onClick={() => setIsOpen(false)}
            className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${location.pathname === '/dashboard' && "bg-gray-700 text-white"}`}
          >
            Dashboard
          </Link>
          <div>
            <button onClick={() => setSubMenu(!subMenu)} type="button" className={`text-gray-300 px-3 py-2 rounded-md text-sm font-medium hover:bg-gray-700 hover:text-white ${(location.pathname === '/report' || location.pathname === '/report-summary') && "bg-gray-700 text-white"} flex items-center w-full p-2 text-base font-normal  transition duration-75 rounded-lg group`} aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">

              <span className="flex-1 text-left whitespace-nowrap" >Status</span>
              <svg className={`${subMenu ? '' : ''} w-6 h-6`} fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
            <ul className={`${subMenu ? 'block' : 'hidden'} py-2 space-y-2`}>
              <li>
                <Link
                  className={`group flex w-full items-center rounded-md px-2 pl-10 text-gray-300 py-2 text-sm hover:bg-opacity-[0.1] hover:bg-[#616161] ${(location.pathname === '/report') && "bg-gray-700 text-white"}`}
                  onClick={() => setIsOpen(false)}
                  to={"/report"}
                >
                  Report
                </Link>
              </li>
              <li>
                <Link
                  className={` group flex w-full items-center rounded-md px-2 pl-10 py-2 text-gray-300 text-sm hover:bg-opacity-[0.1] hover:bg-[#616161] ${(location.pathname === '/report-summary') && "bg-gray-700 text-white"}`}
                  onClick={() => setIsOpen(false)}
                  to={"/report-summary"}
                >
                  Report Summary
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;