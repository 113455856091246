import React from 'react';

function LandingPage() {
  return (
    <div className="h-full flex p-4 sm:p-8 justify-center items-center mt-8">
      <div className="md:max-w-2xl lg:max-w-4xl bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4">SMS Services Company</h1>
        <p className="mb-8 text-sm md:text-[16px]">
          We provide reliable and affordable SMS services to businesses of all sizes. Our platform is easy to use and provides a range of features to help you reach your audience quickly and effectively.
        </p>
        <ul className="list-disc list-inside mb-8 text-sm md:text-[16px]">
          <li>Send bulk SMS messages to thousands of customers in seconds</li>
          <li>Customize your messages with merge tags and personalization</li>
          <li>Track delivery and response rates to optimize your campaigns</li>
          <li>Integrate with your existing systems using our API</li>
        </ul>
        <div className="flex justify-center">
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Sign up for free
          </button>
        </div>
      </div>
      
    </div>
  );
}

export default LandingPage;
