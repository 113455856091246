import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import LandingPage from './pages/LandingPage';
import OtherPage from './pages/OtherPage';
import PricingPage from './pages/PricingPage';
import ContactPage from './pages/ContactPage';
import { AuthProvider, useAuth } from './components/context/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginPage from './components/LoginPage';
import ProfilePage from './pages/ProfilePage';
import ScheduleSmsPage from './pages/ScheduleSmsPage';
import EditScheduleSmsPage from './pages/EditScheduleSmsPage';
import AdminPage from './pages/AdminPage';
import CreateUserPage from './pages/CreateUserPage';
import ReportPage from './pages/ReportPage';
import SummaryPage from './pages/SummaryPage';
import SignUp from './components/SignUp';
import AdminDetailPage from './pages/AdminDetailPage';
import Link from './pages/Link';
import Dashboard from './pages/Dashboard';


function App() {
  const auth = useAuth();
  const isAuthenticated = auth ? auth.isAuthenticated : false;
  const email = auth ? auth.email : '';

  console.log(`Is authenticated? ${isAuthenticated}`);
  console.log(`User email: ${email}`);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>

    <AuthProvider>
      <Router>
          <Navbar />
          <div className="mx-auto flex-grow min-h-[calc(100vh_-_64px_-_128px)]">
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/contact" element={<ContactPage />} />
              <Route path="/schedule-messages" element={<OtherPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signUp" element={<SignUp />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/scheduled-sms" element={<ScheduleSmsPage />} />
              <Route path="/scheduled-message/:id" element={<EditScheduleSmsPage />} />
              <Route path="/admin" element={<AdminPage />} />
              <Route path="/admin/profile/:id" element={<ProfilePage />} />
              <Route path="/admin/create-user" element={<CreateUserPage />} />
              <Route path="/report" element={<ReportPage />} />
              <Route path="/report-summary" element={<SummaryPage />} />
              <Route path="/admin/detail/:id" element={<AdminDetailPage />} />
              <Route path="/link" element={<Link />} />
              <Route path="/dashboard" element={<Dashboard />} />
            </Routes>
          </div>
          <Footer />
      </Router>
    </AuthProvider>
    </GoogleOAuthProvider>

  );
}

export default App;
