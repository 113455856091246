import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import { useAuth } from '../components/context/AuthContext';
import Loader from '../components/Loader';
import ResponseModal from '../components/ResponseModal';

const SummaryPage = () => {
    const navigate = useNavigate();
    const { handleSignOut, accessToken } = useAuth();

    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [deliveredCount, setDeliveredCount] = useState(0);
    const [undeliveredCount, setUnDeliveredCount] = useState(0);
    const [failedCount, setFailedCount] = useState(0);
    const [sentCount, setSentCount] = useState(0);
    const [filterBy, setFilterBy] = useState('24');

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    useEffect(() => {
        fetchMessageSummary(filterBy);
        // eslint-disable-next-line
    }, [])

    const fetchMessageSummary = (filter) => {
        const url = `${BACKEND_BASE_URL}/api/message_summary?filter_by=${filter}`
        setIsLoading(true);

        axios.get(url, {
            headers: headers,
        }).then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setDeliveredCount(response.data?.delivered)
                setUnDeliveredCount(response.data?.undelivered)
                setFailedCount(response.data?.failed)
                setSentCount(response.data?.sent)
            };
        }).catch((error) => {
            setIsLoading(false);
            setApiResponse(error.message);
            console.log("error", error)
            if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                handleSignOut()
                navigate('/login')
            };
        })
    };

    const handleFilter = (e) => {
        let val = e.target.value;
        setFilterBy(val);
        fetchMessageSummary(val);
    };

    return (
        <>
            {
                isLoading && <Loader />
            }
            {apiResponse && (
                <ResponseModal
                    apiResponse={apiResponse}
                    onClick={() => { setIsLoading(false); setApiResponse(null); }}
                />
            )}

            <div className='bg-gray-50'>
                <div className='max-w-[1200px] flex flex-col min-h-[calc(100vh_-_64px_-_128px)] mx-auto p-[50px] max-md:p-[30px] max-sm:p-[20px]'>
                    <div className='block sm:flex justify-between mb-3'>
                        <div></div>
                        <div></div>
                        <h1 className='text-center mb-4 sm:mb-0 text-[25px]'>Past Message Summary</h1>
                        <div className='text-right'>
                            <label htmlFor="filterBy" className="m-[5px] font-medium capitalize">
                                filter by
                            </label>
                            <select
                                id="filterBy"
                                name="filter_by"
                                value={filterBy}
                                onChange={handleFilter}
                                className="p-[12px] border border-slate-300 bg-slate-100 cursor-pointer mr-2 rounded outline-none"
                            >
                                <option key='1' value='24'>24 Hours</option>
                                <option key='2' value='7'>7 Days</option>
                                <option key='3' value=''>All</option>
                            </select>
                        </div>
                    </div>
                    <div className='flex-grow flex flex-col justify-center'>

                        <div className='w-full flex flex-col md:flex-row gap-6 mb-6'>
                            <div className='w-full md:w-1/2'>
                                <div className='shadow-md flex flex-col rounded-[5px] h-[180px]'>
                                    <div className='h-[4px] w-full bg-green-600'></div>
                                    <div className='p-[10px] flex-grow h-full'>
                                        <div className='flex items-center flex-col h-full'>
                                            <h2 className='text-[22px] text-center'>No. of Delivered message</h2>
                                            <div className='flex-grow grid place-items-center text-[35px] font-[600]'>
                                                {deliveredCount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full md:w-1/2'>
                                <div className='shadow-md flex flex-col rounded-[5px] h-[180px]'>
                                    <div className='h-[4px] w-full bg-[indianred]'></div>
                                    <div className='p-[10px] flex-grow h-full'>
                                        <div className='flex items-center flex-col h-full'>
                                            <h2 className='text-[18px] sm:text-[22px] text-center'>No. of UnDelivered message</h2>
                                            <div className='flex-grow grid place-items-center text-[35px] font-[600]'>
                                                {undeliveredCount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='w-full flex flex-col md:flex-row gap-6'>
                            <div className='w-full md:w-1/2'>
                                <div className='shadow-md flex flex-col rounded-[5px] h-[180px]'>
                                    <div className='h-[4px] w-full bg-black'></div>
                                    <div className='p-[10px] flex-grow h-full'>
                                        <div className='flex items-center flex-col h-full'>
                                            <h2 className='text-[18px] sm:text-[22px] text-center'>No. of Sent message</h2>
                                            <div className='flex-grow grid place-items-center text-[35px] font-[600]'>
                                                {sentCount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className='w-full md:w-1/2'>
                                <div className='shadow-md flex flex-col rounded-[5px] h-[180px]'>
                                    <div className='h-[4px] w-full bg-red-600'></div>
                                    <div className='p-[10px] flex-grow h-full'>
                                        <div className='flex items-center flex-col h-full'>
                                            <h2 className='text-[18px] sm:text-[22px] text-center'>No. of Failed message</h2>
                                            <div className='flex-grow grid place-items-center text-[35px] font-[600]'>
                                                {failedCount}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
};

export default SummaryPage;
