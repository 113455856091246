import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { BsEyeFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import { useAuth } from '../components/context/AuthContext';
import ReactPaginate from 'react-paginate';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import Loader from '../components/Loader';
import ResponseModal from '../components/ResponseModal';
import { TbReload } from "react-icons/tb";

const ReportPage = () => {
    const navigate = useNavigate();
    const { handleSignOut, accessToken } = useAuth();

    const [messagesData, setMessagesData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [nextPage, setNextPage] = useState(0);
    const [nextPageToken, setNextPageToken] = useState('');
    const [previousPageToken, setPreviousPageToken] = useState('');
    const [filterTime, setFilterTime] = useState('24');
    const [spin, setSpin] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    useEffect(() => {
        fetchMessageLogs(currentPage, '', filterTime);
        // eslint-disable-next-line
    }, [])

    const fetchMessageLogs = (page, token, time) => {
        setIsLoading(true);
        axios.get(`${BACKEND_BASE_URL}/api/message_logs?page=${page}&page_token=${token}&filter_by=${time}`, {
            headers: headers,
        }).then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                setMessagesData(response.data?.messages)
                setCurrentPage(response.data?.current_page)
                setNextPageToken(response.data?.next_page_token)
                setPreviousPageToken(response.data?.previous_page_token)
                response.data?.next_page && setNextPage(+response.data.next_page)
            };
        }).catch((error) => {
            setIsLoading(false);
            setApiResponse(error.response.data);
            console.log("error", error)
            if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                handleSignOut()
                navigate('/login')
            };
        })
    };

    const handlePageChange = (page) => {
        let selectedPage = page.selected
        setCurrentPage(selectedPage);
        if (selectedPage > currentPage) {
            fetchMessageLogs(selectedPage, nextPageToken, filterTime);
        } else {
            fetchMessageLogs(selectedPage, previousPageToken, filterTime);
        }
    };

    const fetchMessageReport = () => {
        setIsLoading(true);
        axios.get(`${BACKEND_BASE_URL}/api/message_report/`, {
            headers: headers,
        }).then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                download_csv(response.data)
            };
        }).catch((error) => {
            setIsLoading(false);
            setApiResponse(error.response.data);
            console.log("error", error)
            if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                handleSignOut()
                navigate('/login')
            };
        })
    };

    const download_csv = (data) => {
        const date = new Date().toISOString().slice(0, 10);
        const blob = new Blob([data], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', url)
        a.setAttribute('download', `message-logs_${date}.csv`);
        a.click()
    };

    const handleFilter = (e) => {
        const filterBy = e.target.value;
        setNextPage(0);
        setNextPageToken('');
        setCurrentPage(0);
        setFilterTime(filterBy);
        fetchMessageLogs(0, '', filterBy);
    };
    
    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };

    return (
        <>
            {
                isLoading && <Loader />
            }
            {apiResponse && (
                <ResponseModal
                    apiResponse={apiResponse}
                    onClick={() => { setIsLoading(false); setApiResponse(null); }}
                />
            )}

            <div className='bg-gray-50'>
                <div className='max-w-[1200px] mx-auto p-[50px] max-md:p-[30px] max-sm:p-[20px]'>
                <h1 className='text-center block mb-5 md:hidden text-[25px] right-[38%]'>Past Message's Logs</h1>
                    <div className='sm:flex justify-between mb-3'>
                        <div className='text-left sm:text-right'>
                            <label htmlFor="filterBy" className="m-[5px] sm:inline-block sm:mr-2 block font-medium capitalize">
                                filter by
                            </label>
                            <select
                                id="time"
                                name="time"
                                value={filterTime}
                                onChange={handleFilter}
                                className="pl-[12px] pr-[16px] py-2.5 sm:w-auto w-full border border-slate-300 bg-slate-100 cursor-pointer mr-2 rounded outline-none"
                            >
                                <option key='1' value='24'>24 Hours</option>
                                <option key='2' value='7'>7 Days</option>
                                <option key='3' value=''>All</option>
                            </select>
                        </div>
                        <h1 className='text-center hidden md:block text-[25px] right-[38%]'>Past Message's Logs</h1>
                        <div className='text-right flex items-center justify-end gap-2'>
                            <button
                                type="submit"
                                className="sm:w-auto flex-1 w-full mt-3 sm:mt-0 inline p-2 bg-[#1F2937] text-white font-semibold rounded"
                                onClick={fetchMessageReport}
                            >
                                Export to CSV
                            </button>
                            <button
                                type="submit"
                                className="w-auto group mt-3 sm:mt-0 inline p-3 bg-[#1F2937] text-white font-semibold rounded"
                                onClick={() => {
                                    fetchMessageLogs(0, '', '24');
                                    setCurrentPage(0); 
                                    setFilterTime('24');
                                    setNextPageToken('');
                                    setPreviousPageToken('');
                                    setNextPage(0);
                                    refreshCanvas();
                                }}
                            >
                              <TbReload className={spin ? 'animate-spin' : ''}/>
                            </button>
                        </div>
                    </div>
                    <div className='overflow-x-auto rounded-[8px] shadow-md bg-white'>
                        <table className="table-auto max-w-[1100px] w-full mx-auto">
                            <thead>
                                <tr>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                        Created Date
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                        sent Date
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                        from
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                        to
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase text-center break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid'>
                                        message
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid text-center'>
                                        status
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid text-center'>
                                        #
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {messagesData && messagesData?.map((msg, index) => (
                                    <tr className='border-[#e1e3ea] border-b-[1px]' key={index}>
                                        <td className='py-[16px] whitespace-nowrap px-[16px]'>{msg.date_created}</td>
                                        <td className='py-[16px] whitespace-nowrap px-[16px]'>{msg.date_sent}</td>
                                        <td className='py-[16px] whitespace-nowrap px-[16px]'>{msg.from}</td>
                                        <td className='py-[16px] whitespace-nowrap px-[16px]'>{msg.to}</td>
                                        <td className='text-center whitespace-nowrap'>
                                            <BsEyeFill
                                                size={"21px"}
                                                className='mx-auto text-blue-500 cursor-pointer'
                                                data-tooltip-id={index+1}
                                            />
                                        </td>
                                        <td
                                            className={`text-center capitalize ${msg.status === "received" ? "text-orange-500"
                                            : msg.status === "undelivered" ? "text-[indianred]"
                                            : msg.status === "delivered" ? "text-green-500"
                                            : msg.status === "failed" ? "text-red-600"
                                            :''}`}
                                        >
                                            {msg.status}
                                        </td>
                                        <td className='text-center'>
                                            {msg.error_message ?
                                                <AiOutlineInfoCircle
                                                    size={"20px"}
                                                    className='text-red-700 mx-auto cursor-pointer'
                                                    data-tooltip-id={`${msg.error_message}${index+1}`}
                                                />
                                                : '-'
                                            }
                                        </td>
                                        <ReactTooltip id={index+1} content={msg.body} className='!bg-[#121c2d] max-w-[300px] w-full text-justify' />
                                        <ReactTooltip id={`${msg.error_message}${index+1}`} content={msg.error_message} className='!bg-[#121c2d] max-w-[300px] w-full text-justify' />
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {
                            !messagesData?.length && <div className='my-[30px] text-center text-[23px]'>There are no past messages to display</div>
                        }
                    </div>
                    {
                        nextPage > 0 && (
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next >"
                                onPageChange={handlePageChange}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={0}
                                pageCount={nextPage+1}
                                currentPage={currentPage}
                                previousLabel="< Previous"
                                renderOnZeroPageCount={null}
                                className='flex justify-center gap-[20px] mt-[30px] items-center'
                                pageLinkClassName='hover:bg-blue-600 grid place-items-center hover:text-white h-[33px] inline-block w-[33px] rounded-full'
                                previousClassName='bg-[#111827] text-white p-2 rounded'
                                nextClassName='bg-[#111827] text-white p-2 rounded'
                                activeLinkClassName='bg-blue-500 place-items-center text-white py-[5px] px-[8px] h-[33px] inline-block w-[33px] rounded-full'
                            />
                        )
                    }
                </div>
            </div>
        </>
    )
};

export default ReportPage;
