import React from 'react';
import DialogLoader from './DialogLoader';

const DialogBody = ({ children, isDialogLoading, className }) => {
    
    if (isDialogLoading) {
        return <div className={`sm:h-[500px] relative ${className}`}><DialogLoader /></div> 
    };

    return (
        <>
            <div className={`sm:h-[500px] relative ${className}`}>
                {children}
            </div>
        </>
    )
};

export default DialogBody;
