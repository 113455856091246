import axios from 'axios';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import { useAuth } from '../components/context/AuthContext';
import { BiArrowBack, BiSolidHide, BiSolidShow } from 'react-icons/bi';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PhoneInput from 'react-phone-input-2';
import { Dialog, Transition } from '@headlessui/react'
import Loader from '../components/Loader';
import ResponseModal from '../components/ResponseModal';

const CreateUserPage = () => {
    const navigate = useNavigate();
    const { handleSignOut, accessToken } = useAuth();
    const initialState = {
        email: '',
        username: '',
        first_name: '',
        last_name: '',
        provider: '',
        client_id: '',
        secret_key: '',
        password: '',
        mobile_no: '',
        is_otp_verification: true,
        is_mobile_no_verified: false,
        is_active: true,
        is_admin: false,
    };
    const [formData, setFormData] = useState(initialState);
    const [confirmPassword, setConfirmPassword] = useState('');
    const [providerData, setProviderData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [isShow, setIsShow] = useState(false);

    // otp popUp state
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState('An OTP has been sent to your mobile number!');
    const [otp, setOTP] = useState(['', '', '', '', '', '']);
    const cancelButtonRef = useRef(null);
    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    // verify attempt state
    const [timeLeft, setTimeLeft] = useState(null);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    }

    useEffect(() => {
        if (timeLeft === 0) {
            console.log("TIME LEFT IS 0");
            setTimeLeft(null);
            sendOTPAgain();
        };

        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [timeLeft])

    const fetchProvider = () => {
        axios.get(`${BACKEND_BASE_URL}/api/provider/`, {
            headers: headers,
        }).then((response) => {
            if (response.status === 200) {
                setProviderData(response?.data)
            }
        }).catch((error) => {
            console.log("error", error)
            if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                handleSignOut()
                navigate('/login')
            };
        })
    };

    useEffect(() => {
        fetchProvider()
        // eslint-disable-next-line
    }, [])

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (formData.password === confirmPassword) {
            if (formData.mobile_no && formData.mobile_no?.length && formData.is_otp_verification) {
                sendOTP();
            } else {
                createUser(formData);
            };
        } else {
            setApiResponse("Password and Confirm Password do not match.")
        };
    };

    const createUser = (data) => {
        const url = `${BACKEND_BASE_URL}/api/admin_user/`
        if (formData.password === confirmPassword) {
            setIsLoading(true);
            axios.post(url, data, { headers: headers })
                .then((response) => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        setFormData(initialState);
                        setConfirmPassword('');
                        setApiResponse(response.data);
                    }
                }).catch((error) => {
                    setIsLoading(false);
                    setApiResponse(error.response.data);
                    console.log("error", error)
                    if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                        handleSignOut()
                        navigate('/login')
                    };
                })
        } else {
            setApiResponse("Password and Confirm Password do not match.")
        }
    };

    const closeDialog = () => {
        setOpen(true);
    };

    const handleOTP = (e, index) => {
        const value = e.target.value;

        if (value.length === 1 && index < 5) {
            inputRefs[index + 1].current.focus();
        } else if (value.length === 0 && index > 0) {
            inputRefs[index - 1].current.focus();
        };

        const updatedOTP = [...otp];
        updatedOTP[index] = value;
        setOTP(updatedOTP);
        setError(false);
    };

    const verifiyOTP = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        const url = `${BACKEND_BASE_URL}/api/otp/?mobile_no=${formData.mobile_no}`
        const data = {
            'otp': otp.join('')
        }
        axios.post(url, data, { headers: headers })
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    if (response.data?.status === true) {
                        setOpen(false);
                        setError(false);
                        setMsg(null);
                        setTimeLeft(null);

                        let newFormData = new FormData();
                        newFormData.append('email', formData.email);
                        newFormData.append('username', formData.username);
                        newFormData.append('first_name', formData.first_name);
                        newFormData.append('last_name', formData.last_name);
                        newFormData.append('provider', formData.provider);
                        newFormData.append('client_id', formData.client_id);
                        newFormData.append('secret_key', formData.secret_key);
                        newFormData.append('password', formData.password);
                        newFormData.append('mobile_no', formData.mobile_no);
                        newFormData.append('is_mobile_no_verified', true);
                        newFormData.append('is_otp_verification', formData.is_otp_verification);
                        newFormData.append('is_active', formData.is_active);
                        newFormData.append('is_admin', formData.is_admin);

                        createUser(newFormData);
                    } else {
                        setOTP(['', '', '', '', '', '']);
                        setError(true);
                        setMsg(response.data?.message);
                    };
                }
                else {
                    console.log("error")
                };
            }).catch((error) => {
                setIsLoading(false);
                setOpen(false);
                setApiResponse(error.response.data);
                console.log("error", error);
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
    };

    const sendOTP = async () => {
        setIsLoading(true);
        const url = `${BACKEND_BASE_URL}/api/otp/?mobile_no=${formData.mobile_no}`

        axios.get(url, { headers: headers })
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    setOTP(['', '', '', '', '', '']);
                    setOpen(true);
                }
                else {
                    setApiResponse(response.data);
                };
            }).catch((error) => {
                setIsLoading(false);
                setApiResponse(error.response.data);
                console.log("error", error);
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
    };

    const sendOTPAgain = () => {
        const url = `${BACKEND_BASE_URL}/api/otp/?mobile_no=${formData.mobile_no}`
        setError(false);
        setMsg('Sending...');

        axios.get(url, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    setOTP(['', '', '', '', '', '']);
                    setMsg('A new OTP has been sent to your mobile number!');
                }
                else {
                    setOpen(false);
                    setApiResponse(response.data);
                };
            }).catch((error) => {
                setOpen(false);
                setApiResponse(error.response.data);
                console.log("error", error);
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
    };

    return (
        <>
            {
                isLoading && <Loader />
            }
            {apiResponse && (
                <ResponseModal
                    apiResponse={apiResponse}
                    onClick={() => { setIsLoading(false); setApiResponse(null); }}
                />
            )}

            <Transition.Root show={open} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeDialog}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                    <div>
                                        <div className="mt-3 text-center sm:mt-5">
                                            <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-blue-500">
                                                OTP Verification
                                            </Dialog.Title>
                                            {
                                                msg && <span className={error ? "text-red-500" : ""}>{msg}</span>
                                            }
                                            <div className="otp-input-container mt-2 flex gap-2 justify-center">
                                                {otp.map((digit, index) => (
                                                    <input
                                                        key={index}
                                                        ref={inputRefs[index]}
                                                        type='text'
                                                        value={digit}
                                                        onChange={(e) => handleOTP(e, index)}
                                                        className="otp-input border rounded-md p-2 bg-[#FFF] text-center focus:outline-none w-[35px] h-[35px] sm:w-[40px] sm:h-[40px inline-flex sm:w-2/4 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 sm:col-start-1 sm:mt-0]"
                                                        maxLength={1}
                                                        onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                    />
                                                ))}
                                            </div>
                                            {
                                                timeLeft && (
                                                    <>
                                                        <span className='text-sm text-gray-500'>Resend code in </span><span className='text-sm font-medium'>{timeLeft} seconds</span>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:grid-flow-row-dense sm:grid-cols-2 gap-2 justify-center sm:gap-3 flex">

                                        <button
                                            type="button"
                                            className="inline-flex sm:w-2/4 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 sm:col-start-1 sm:mt-0"
                                            onClick={() => { setOpen(false); setError(false); setMsg(null); setTimeLeft(null); }}
                                            ref={cancelButtonRef}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            type="button"
                                            className={`inline-flex sm:w-2/4 justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 ${!timeLeft ? 'bg-white hover:bg-gray-100' : 'bg-gray-100'} sm:col-start-1 sm:mt-0`}
                                            onClick={() => { setTimeLeft(30); }}
                                            disabled={timeLeft}
                                        >
                                            Send Again
                                        </button>
                                        <button
                                            type="button"
                                            className={`inline-flex sm:w-2/4 justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${!timeLeft ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-indigo-400'} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2`}
                                            onClick={verifiyOTP}
                                            disabled={timeLeft}
                                        >
                                            Verify
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

            <div className="min-h-[calc(100vh_-_64px_-_128px)] flex-col bg-gray-100 flex items-center justify-center py-5 px-5" >
                <div className='flex flex-col justify-center max-w-[900px] w-full'>
                    <div className='flex items-center mb-[15px]'>
                        <div
                            className='w-[30px] h-[30px] sm:w-[35px] sm:h-[35px] border cursor-pointer hover:bg-gray-800 group border-gray-800 rounded-full grid place-items-center'
                            onClick={() => navigate('/admin')}
                        >
                            <BiArrowBack
                                size={'20px'}
                                className='group-hover:text-white'
                            />
                        </div>
                        <h1 className='text-center capitalize flex-grow text-[20px] sm:text-[22px] md:text-[25px]'>create a new user</h1>
                    </div>
                </div>
                <div className="bg-white p-8 rounded-lg shadow-md w-full  max-w-[900px]">
                    <form className="space-y-4" onSubmit={handleSubmit}>
                        <div className='flex gap-[12px] max-md:flex-col'>
                            <div className='w-1/2 max-md:w-full'>
                                <div className="mb-3">
                                    <label htmlFor="email" className="mb-[5px] text-[14px] md:text-[16px] capitalize block">
                                        <span className="text-red-600">*</span>
                                        email
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full text-[14px] md:text-[16px] p-2.5  md:p-3 border border-gray-300 rounded"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="password" className="mb-[5px] text-[14px] md:text-[16px] capitalize block">
                                    <span className="text-red-600">*</span>
                                        password
                                    </label>
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={formData.password}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full text-[14px] md:text-[16px] p-2.5 md:p-3 border border-gray-300 rounded"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="userName" className="mb-[5px] capitalize text-[14px] md:text-[16px] block">
                                        username
                                    </label>
                                    <input
                                        id="userName"
                                        name="username"
                                        type="text"
                                        value={formData.username}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full text-[14px] md:text-[16px] p-2.5 md:p-3 border border-gray-300 rounded"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="firstName" className="mb-[5px] block text-[14px] md:text-[16px] capitalize">
                                        first name
                                    </label>
                                    <input
                                        id="firstName"
                                        name="first_name"
                                        type="text"
                                        value={formData.first_name}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full text-[14px] md:text-[16px] p-2.5 md:p-3 border border-gray-300 rounded"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="lastName" className="mb-[5px] block text-[14px] md:text-[16px] capitalize">
                                        last name
                                    </label>
                                    <input
                                        id="lastName"
                                        name="last_name"
                                        type="text"
                                        value={formData.last_name}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full text-[14px] md:text-[16px] p-2.5 md:p-3 border border-gray-300 rounded"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="OTPVerification" className="mb-[5px] text-[14px] md:text-[16px] capitalize flex items-center cursor-pointer">
                                        <input
                                            id="OTPVerification"
                                            name="is_otp_verification"
                                            type="checkbox"
                                            checked={formData.is_otp_verification}
                                            onChange={(e) => setFormData({ ...formData, 'is_otp_verification': e.target.checked })}
                                            className="mr-2 cursor-pointer"
                                        />
                                        OTP verification
                                    </label>
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="isActive" className="capitalize text-[14px] md:text-[16px]">
                                    <input
                                        id="isActive"
                                        name="is_active"
                                        type="checkbox"
                                        checked={formData.is_active}
                                        onChange={(e) => setFormData({...formData, is_active: e.target.checked})}
                                        className="mr-2"
                                    />
                                        is active
                                    </label>
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="isAdmin" className="capitalize text-[14px] md:text-[16px]">
                                    <input
                                        id="isAdmin"
                                        name="is_admin"
                                        type="checkbox"
                                        checked={formData.is_admin}
                                        onChange={(e) => setFormData({...formData, is_admin: e.target.checked})}
                                        className="mr-2"
                                    />
                                        is admin
                                    </label>
                                </div>
                            </div>
                            <div className='w-1/2 max-md:w-full'>
                                <div className='mb-3'>
                                    <label htmlFor="mobile_no" className="mb-[5px] text-[14px] md:text-[16px] capitalize flex items-center">
                                        <div>
                                            Mobile No
                                        </div>
                                        <BsInfoCircleFill
                                            className='text-blue-600 ml-2 cursor-pointer'
                                            data-tooltip-id="info"
                                        />
                                        <ReactTooltip
                                            id="info"
                                            content="It should be your Phone Number for OTP verification"
                                            className='!bg-[#121c2d]'
                                        />
                                    </label>
                                    <PhoneInput
                                        country={'us'}
                                        inputClass='!w-full !h-[43px] md:!h-[50px]'
                                        value={formData.mobile_no || '+1'}
                                        onChange={phone => setFormData({ ...formData, 'mobile_no': phone })}
                                    />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="password" className="mb-[5px] text-[14px] md:text-[16px] block capitalize">
                                    <span className="text-red-600">*</span>
                                        confirm password
                                    </label>
                                    <input
                                        id="password"
                                        name="confirm_password"
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className="block w-full text-[14px] md:text-[16px] p-2.5 md:p-3 border border-gray-300 rounded mb-2"
                                    />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="provider" className="mb-[5px] text-[14px] md:text-[16px] capitalize block">
                                        provider
                                    </label>
                                    <select
                                        id="provider"
                                        name="provider"
                                        value={formData.provider}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full text-[14px] md:text-[16px] p-2.5 md:p-[14px] border border-gray-300 rounded"
                                    >
                                        <option value="" disabled >Select a Provider</option>
                                        {providerData && providerData?.map((ele) => (
                                            <option key={ele.id} value={ele.id}>
                                                {ele.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="clinetId" className="mb-[5px] text-[14px] md:text-[16px] block capitalize">
                                        client id
                                    </label>
                                    <input
                                        id="clinetId"
                                        name="client_id"
                                        type="text"
                                        value={formData.client_id}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full text-[14px] md:text-[16px] p-2.5 md:p-3 border border-gray-300 rounded mb-2"
                                    />
                                </div>

                                <div className='mb-2'>
                                    <label htmlFor="secretKey" className="mb-[5px] text-[14px] md:text-[16px] block capitalize">
                                        secret key
                                    </label>
                                    <div className='relative'>
                                        <input
                                            id="secretKey"
                                            name="secret_key"
                                            type={isShow ? "text" : "password"}
                                            value={formData.secret_key}
                                            onChange={(e) => handleChange(e)}
                                            className="block w-full text-[14px] md:text-[16px] p-2.5 md:p-3 border border-gray-300 rounded mb-2"
                                        />
                                        {
                                            formData.secret_key && (
                                                !isShow ?
                                                    <BiSolidShow
                                                        size={"25px"}
                                                        className='mx-auto inline outline-none mr-2 absolute cursor-pointer right-0 top-3'
                                                        onClick={() => setIsShow(true)}
                                                    />
                                                    :
                                                    <BiSolidHide
                                                        size={"25px"}
                                                        className='mx-auto inline outline-none mr-2 absolute cursor-pointer right-0 top-3'
                                                        onClick={() => setIsShow(false)}
                                                    />
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <button
                                type="submit"
                                className={`inline p-3 ${(/\S+@\S+\.\S+/.test(formData.email) && formData.password && confirmPassword) ? "bg-blue-600" : "bg-gray-400" } text-white font-semibold rounded`}
                                disabled={!(/\S+@\S+\.\S+/.test(formData.email) && formData.password && confirmPassword)}
                            >
                                Create
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};

export default CreateUserPage;
