import axios from 'axios';
import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import { useAuth } from '../components/context/AuthContext';
import { BiArrowBack, BiSolidHide, BiSolidShow } from 'react-icons/bi';
import { BsInfoCircleFill } from 'react-icons/bs';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import PhoneInput from 'react-phone-input-2'
import { Dialog, Transition } from '@headlessui/react'
import 'react-phone-input-2/lib/style.css'
import Popup from '../components/Popup';
import Loader from '../components/Loader';
import ResponseModal from '../components/ResponseModal';

const ProfilePage = () => {
    const navigate = useNavigate();
    const params =  useParams();
    const { handleSignOut, accessToken } = useAuth();

    const [formData, setFormData] = useState({
        email: '',
        username: '',
        first_name: '',
        last_name: '',
        provider: '',
        client_id: '',
        secret_key: '',
        mobile_no: '',
        is_mobile_no_verified: false,
        is_otp_verification: true
    });
    const [providerData, setProviderData] = useState();
    const [isShow, setIsShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);

    // otp popUp state
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState('An OTP has been sent to your mobile number!');
    const [otp, setOTP] = useState(['', '', '', '', '', '']);
    const cancelButtonRef = useRef(null);
    const inputRefs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()];

    // verify attempt state
    const [timeLeft, setTimeLeft] = useState(null);
    const [verifyAttemptTime, setVerifyAttemptTime] = useState(null);
    const [verifyAttempts, setVerifyAttempts] = useState(0);
    const [isError, setIsError] = useState(false);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    }

    useEffect(() => {
        if (timeLeft === 0) {
            console.log("TIME LEFT IS 0");
            setTimeLeft(null);
            sendOTPAgain();
        };

        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            setTimeLeft(timeLeft - 1);
        }, 1000);

        return () => clearInterval(intervalId);
        // eslint-disable-next-line
    }, [timeLeft])

    useEffect(() => {
        if (verifyAttemptTime === 0) {
            setVerifyAttemptTime(null);
            setVerifyAttempts(0);
        };

        if (!verifyAttemptTime) return;

        const intervalId = setInterval(() => {
            setVerifyAttemptTime(verifyAttemptTime - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [verifyAttemptTime])

    const fetchProfile = () => {
        if (params && params?.id) {
            axios.get(`${BACKEND_BASE_URL}/api/admin_user/${params.id}/`, {
                headers: headers,
            }).then((response) => {
                if (response.status === 200) {
                    setFormData({
                        email: response.data?.email,
                        username: response.data?.username,
                        first_name: response.data?.first_name,
                        last_name: response.data?.last_name,
                        provider: response.data?.provider.id,
                        client_id: response.data?.client_id,
                        secret_key: response.data?.secret_key,
                        mobile_no: response.data?.mobile_no,
                        is_mobile_no_verified: response.data?.is_mobile_no_verified,
                        is_otp_verification: response.data?.is_otp_verification
                    })
                }
            }).catch((error) => {
                console.log("error", error)
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
        } else {
            axios.get(`${BACKEND_BASE_URL}/api/profile/`, {
                headers: headers,
            }).then((response) => {
                if (response.status === 200) {
                    setFormData({
                        email: response.data?.email,
                        username: response.data?.username,
                        first_name: response.data?.first_name,
                        last_name: response.data?.last_name,
                        provider: response.data?.provider,
                        client_id: response.data?.client_id,
                        secret_key: response.data?.secret_key,
                        mobile_no: response.data?.mobile_no,
                        is_mobile_no_verified: response.data?.is_mobile_no_verified,
                        is_otp_verification: response.data?.is_otp_verification
                    })
                }
            }).catch((error) => {
                console.log("error", error)
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
        };
    };

    const fetchProvider = () => {
        axios.get(`${BACKEND_BASE_URL}/api/provider/`, {
            headers: headers,
        }).then((response) => {
            if (response.status === 200) {
                setProviderData(response?.data)
            }
        }).catch((error) => {
            console.log("error", error)
            if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                handleSignOut()
                navigate('/login')
            };
        })
    };

    useEffect(() => {
        fetchProfile()
        fetchProvider()
        // eslint-disable-next-line
    }, [params])

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value });
    };

    const handleUpdateProfile = (data) => {
        setIsLoading(true);

        if (params && params?.id) {
            axios.put(`${BACKEND_BASE_URL}/api/admin_user/${params.id}/`, data, { headers: headers })
                .then((response) => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        setApiResponse(response.data);
                    };
                })
                .catch((error) => {
                    console.log("error", error);
                    setIsLoading(false);
                    setApiResponse(error.response.data);
                    if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                        handleSignOut()
                        navigate('/login')
                    };
                })
        } else {
            axios.put(`${BACKEND_BASE_URL}/api/profile/`, data, { headers: headers })
                .then((response) => {
                    setIsLoading(false);
                    if (response.status === 200) {
                        setApiResponse(response.data);
                    };
                })
                .catch((error) => {
                    console.log("error", error);
                    setIsLoading(false);
                    setApiResponse(error.response.data);
                    if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                        handleSignOut()
                        navigate('/login')
                    };
                })
        };
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (verifyAttemptTime) {
            setIsError(true);
        } else {
            if (formData.is_mobile_no_verified) {
                handleUpdateProfile(formData);
            } else {
                if (formData.mobile_no && formData.mobile_no?.length && formData.is_otp_verification) {
                    sendOTP();
                } else {
                    handleUpdateProfile(formData);
                };
            };
        };
    };

    const handleDisabled = () => {
        if (formData.provider && formData.client_id?.length && formData.secret_key?.length && formData.mobile_no?.length) {
            return true;
        }
        else if (formData.provider || formData.client_id?.length || formData.secret_key?.length || formData.mobile_no?.length) {
            return false;
        }
        else {
            return true;
        }
    };

    const handleOTP = (e, index) => {
        const value = e.target.value;

        if (value.length === 1 && index < 5) {
            inputRefs[index + 1].current.focus();
        } else if (value.length === 0 && index > 0) {
            inputRefs[index - 1].current.focus();
        };

        const updatedOTP = [...otp];
        updatedOTP[index] = value;
        setOTP(updatedOTP);
        setError(false);
    };

    const verifiyOTP = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        const url = `${BACKEND_BASE_URL}/api/otp/?mobile_no=${formData.mobile_no}`
        const data = {
            'otp': otp.join('')
        }
        axios.post(url, data, { headers: headers })
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    if (response.data?.status === true) {
                        setOpen(false);
                        setError(false);
                        setMsg(null);
                        setTimeLeft(null);
                        setVerifyAttempts(0);

                        let newFormData = new FormData();
                        newFormData.append('email', formData.email);
                        newFormData.append('username', formData.username);
                        newFormData.append('first_name', formData.first_name);
                        newFormData.append('last_name', formData.last_name);
                        newFormData.append('provider', formData.provider);
                        newFormData.append('client_id', formData.client_id);
                        newFormData.append('secret_key', formData.secret_key);
                        newFormData.append('mobile_no', formData.mobile_no);
                        newFormData.append('is_mobile_no_verified', true);
                        newFormData.append('is_otp_verification', formData.is_otp_verification);

                        handleUpdateProfile(newFormData);
                    } else {
                        setOTP(['', '', '', '', '', '']);
                        setError(true);
                        setMsg(response.data?.message);
                        setVerifyAttempts(verifyAttempts + 1);
                        if (verifyAttempts >= 2) {
                            setOpen(false);
                            setVerifyAttemptTime(120);
                            setIsError(true);
                        };
                    };
                }
                else {
                    console.log("error")
                };
            }).catch((error) => {
                setIsLoading(false);
                setOpen(false);
                setApiResponse(error.response.data);
                console.log("error", error);
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
    };

    const sendOTP = async () => {
        setIsLoading(true);
        const url = `${BACKEND_BASE_URL}/api/otp/?mobile_no=${formData.mobile_no}`

        axios.get(url, { headers: headers })
            .then((response) => {
                setIsLoading(false);
                if (response.status === 200) {
                    setOTP(['', '', '', '', '', '']);
                    setOpen(true);
                }
                else {
                    setApiResponse(response.data);
                };
            }).catch((error) => {
                setIsLoading(false);
                setApiResponse(error.response.data);
                console.log("error", error);
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
    };

    const sendOTPAgain = () => {
        const url = `${BACKEND_BASE_URL}/api/otp/?mobile_no=${formData.mobile_no}`
        setError(false);
        setMsg('Sending...');

        axios.get(url, { headers: headers })
            .then((response) => {
                if (response.status === 200) {
                    setOTP(['', '', '', '', '', '']);
                    setMsg('A new OTP has been sent to your mobile number!');
                }
                else {
                    setOpen(false);
                    setApiResponse(response.data);
                };
            }).catch((error) => {
                setOpen(false);
                setApiResponse(error.response.data);
                console.log("error", error);
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
    };

    const closeDialog = () => {
        setOpen(true);
    };

    const closeErrorDialog = () => {
        setIsError(true);
    };

    return (
        <>
            <div className="min-h-[calc(100vh_-_64px_-_128px)] flex-col bg-gray-100 flex items-center justify-center py-5 px-5" >
                {
                    isLoading && <Loader />
                }
                {apiResponse && (
                    <ResponseModal
                        apiResponse={apiResponse}
                        onClick={() => { 
                            setIsLoading(false);
                            setApiResponse(null);
                            (params && params?.id) ? navigate('/admin') : navigate('/')
                        }}
                    />
                )}

                <div className='flex flex-col justify-center max-w-[900px] w-full'>
                    <div className='flex items-center mb-[10px]'>
                        {
                            params?.id ? (
                                <>
                                    <div
                                        className='w-[30px] h-[30px] sm:w-[35px] sm:h-[35px] border cursor-pointer hover:bg-gray-800 group border-gray-800 rounded-full grid place-items-center'
                                        onClick={() => navigate('/admin')}
                                    >
                                        <BiArrowBack
                                            size={'20px'}
                                            className='group-hover:text-white'
                                        />
                                    </div>
                                    <h1 className='text-center capitalize flex-grow text-[20px] sm:text-[25px] mb-[15px]'>update profile</h1>
                                </>
                            ) :
                                <h1 className='text-center capitalize flex-grow text-[20px] sm:text-[25px] mb-[15px]'>profile</h1>
                        }
                    </div>
                </div>

                <div className="bg-white p-8 rounded-lg shadow-md w-full  max-w-[900px]">

                    <Transition.Root show={open} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={closeDialog}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                            </Transition.Child>

                            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                                <div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                    >
                                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                            <div>
                                                <div className="mt-3 text-center sm:mt-5">
                                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-blue-500">
                                                        OTP Verification
                                                    </Dialog.Title>
                                                    {
                                                        msg && <span className={error ? "text-red-500" : ""}>{msg}</span>
                                                    }
                                                    <div className="otp-input-container mt-2 flex gap-2 justify-center">
                                                        {otp.map((digit, index) => (
                                                            <input
                                                                key={index}
                                                                ref={inputRefs[index]}
                                                                type='text'
                                                                value={digit}
                                                                onChange={(e) => handleOTP(e, index)}
                                                                className="otp-input border rounded-md p-2 bg-[#FFF] text-center focus:outline-none w-[35px] h-[35px] sm:w-[40px] sm:h-[40px]"
                                                                maxLength={1}
                                                                onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
                                                            />
                                                        ))}
                                                    </div>
                                                    {
                                                        timeLeft && (
                                                            <>
                                                                <span className='text-sm text-gray-500'>Resend code in </span><span className='text-sm font-medium'>{timeLeft} seconds</span>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                            <div className="mt-5  sm:mt-6 sm:grid-flow-row-dense sm:grid-cols-2 gap-2 sm:gap-3 justify-center flex">

                                                <button
                                                    type="button"
                                                    className="inline-flex sm:w-2/4 justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 sm:col-start-1 sm:mt-0"
                                                    onClick={() => { setOpen(false); setError(false); setMsg(null); setTimeLeft(null); setVerifyAttempts(0); setVerifyAttemptTime(null); }}
                                                    ref={cancelButtonRef}
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`inline-flex sm:w-2/4 justify-center rounded-md px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 ${ !timeLeft ? 'bg-white hover:bg-gray-100' : 'bg-gray-100'} sm:col-start-1 sm:mt-0`}
                                                    onClick={() => { setTimeLeft(30); setVerifyAttempts(0); setVerifyAttemptTime(null); }}
                                                    disabled={timeLeft}
                                                >
                                                    Send Again
                                                </button>
                                                <button
                                                    type="button"
                                                    className={`inline-flex sm:w-2/4 justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm ${!timeLeft ? 'bg-indigo-600 hover:bg-indigo-500' : 'bg-indigo-400'} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2`}
                                                    onClick={verifiyOTP}
                                                    disabled={timeLeft}
                                                >
                                                    Verify
                                                </button>
                                            </div>
                                        </Dialog.Panel>
                                    </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition.Root>

                    <Popup
                        isOpen={isError}
                        closeCSVDialog={closeErrorDialog}
                        className="p-4 rounded-md"
                    >
                        <div>
                            <div className="text-center">
                                <Dialog.Title as="h3" className=" font-semibold leading-6 text-xl text-yellow-500">
                                    Many Verification Attempts
                                </Dialog.Title>
                                <div className="otp-input-container mt-4 flex gap-2 justify-center text-gray-800">
                                    You have entered Many wrong verifications, Please try again after a few minutes.
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 flex justify-end">
                            <button
                                type="button"
                                className="mt-3 outline-none bg-slate-400 text-white inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-1 sm:mt-0"
                                onClick={() => { setIsError(false); }}
                            >
                                Close
                            </button>
                        </div>
                    </Popup>

                    <form className="space-y-4" onSubmit={handleSubmit}>
                        <div className='flex gap-[12px] max-md:flex-col'>
                            <div className='w-1/2 max-md:w-full'>
                                <div className="mb-3">
                                    <label htmlFor="email" className="mb-[5px] text-[14px] capitalize block">
                                    <span className="text-red-600">*</span>
                                        email
                                    </label>
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        value={formData.email}
                                        onChange={(e) => handleChange(e)}
                                        readOnly
                                        className="block w-full p-3 border border-gray-300 opacity-[0.7] rounded"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="userName" className="mb-[5px] text-[14px] capitalize block">
                                        username
                                    </label>
                                    <input
                                        id="userName"
                                        name="username"
                                        type="text"
                                        value={formData.username}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full p-3 border border-gray-300 rounded"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="firstName" className="mb-[5px] text-[14px] block capitalize">
                                        first name
                                    </label>
                                    <input
                                        id="firstName"
                                        name="first_name"
                                        type="text"
                                        value={formData.first_name}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full p-3 border border-gray-300 rounded"
                                    />
                                </div>

                                <div className="mb-3">
                                    <label htmlFor="lastName" className="mb-[5px] text-[14px] block capitalize">
                                        last name
                                    </label>
                                    <input
                                        id="lastName"
                                        name="last_name"
                                        type="text"
                                        value={formData.last_name}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full p-3 border border-gray-300 rounded"
                                    />
                                </div>

                                <div className="mb-2">
                                    <label htmlFor="OTPVerification" className="mb-[5px] capitalize flex items-center cursor-pointer">
                                        <input
                                            id="OTPVerification"
                                            name="is_otp_verification"
                                            type="checkbox"
                                            checked={formData.is_otp_verification}
                                            onChange={(e) => setFormData({ ...formData, 'is_otp_verification': e.target.checked })}
                                            className="mr-2 cursor-pointer"
                                        />
                                        OTP verification
                                    </label>
                                </div>
                            </div>
                            <div className='w-1/2  max-md:w-full'>
                                <div className='mb-3'>
                                    <label htmlFor="provider" className="mb-[5px] text-[14px] capitalize block">
                                    <span className="text-red-600">*</span>
                                        provider
                                    </label>
                                    <select
                                        id="provider"
                                        name="provider"
                                        value={formData.provider}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full p-[14px] border border-gray-300 rounded"
                                    >
                                        <option value="" disabled >Select a Provider</option>
                                        {providerData && providerData?.map((ele) => (
                                            <option key={ele.id} value={ele.id}>
                                                {ele.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="clinetId" className="mb-[5px] text-[14px] block capitalize">
                                    <span className="text-red-600">*</span>
                                        client id
                                    </label>
                                    <input
                                        id="clinetId"
                                        name="client_id"
                                        type="text"
                                        value={formData.client_id}
                                        onChange={(e) => handleChange(e)}
                                        className="block w-full p-3 border border-gray-300  rounded mb-2"
                                    />
                                </div>

                                <div className='mb-3'>
                                    <label htmlFor="secretKey" className="mb-[5px] block text-[14px] capitalize">
                                    <span className="text-red-600">*</span>
                                        secret key
                                    </label>
                                    <div className='relative'>
                                        <input
                                            id="secretKey"
                                            name="secret_key"
                                            type={isShow ? "text" : "password"}
                                            value={formData.secret_key}
                                            onChange={(e) => handleChange(e)}
                                            className="block w-full  p-3 pr-[35px] border border-gray-300 rounded mb-2"
                                        />
                                        {
                                            formData.secret_key && (
                                                !isShow ?
                                                    <BiSolidShow
                                                        size={"25px"}
                                                        className='mx-auto inline outline-none mr-2 absolute cursor-pointer right-0 top-3'
                                                        onClick={() => setIsShow(true)}
                                                    />
                                                    :
                                                    <BiSolidHide
                                                        size={"25px"}
                                                        className='mx-auto inline outline-none mr-2 absolute cursor-pointer right-0 top-3'
                                                        onClick={() => setIsShow(false)}
                                                    />
                                            )
                                        }
                                    </div>
                                </div>
                                    <div className='mb-3'>
                                        <label htmlFor="mobile_no" className="mb-[5px] text-[14px] capitalize flex items-center">
                                            <div>
                                                <span className="text-red-600">*</span>
                                                Mobile no
                                            </div>
                                            <BsInfoCircleFill
                                                className='text-blue-600 ml-2 cursor-pointer'
                                                data-tooltip-id="info"
                                            />
                                            <ReactTooltip
                                                id="info"
                                                content="It should be your Phone Number for OTP verification"
                                                className='!bg-[#121c2d]'
                                            />
                                        </label>
                                        <PhoneInput
                                            country={'us'}
                                            inputClass='!w-full !h-[50px]'
                                            value={formData.mobile_no || '+1'}
                                            onChange={phone => setFormData({ ...formData, 'mobile_no': phone })}
                                            disabled={formData.is_mobile_no_verified}
                                        />
                                    </div>
                            </div>
                        </div>
                        <div className='text-center'>
                            <button
                                type="submit"
                                className={`inline p-3 ${handleDisabled()
                                    ? "bg-blue-600 hover:bg-blue-700"
                                    : "bg-gray-400"
                                } text-white font-semibold rounded`}
                                disabled={(!handleDisabled())}
                            >
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default ProfilePage;
