import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from 'jwt-decode';
import { BACKEND_BASE_URL } from './common/apiEnv';
import Loader from './Loader';
import ResponseModal from './ResponseModal';

const LoginPage = () => {
  const navigate = useNavigate();
  const { isAuthenticated, handleSignIn } = useAuth();

  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });

  useEffect(() => {
    if (isAuthenticated) {
      console.log('User is already authenticated');
      navigate('/schedule-messages');
    }
  }, [isAuthenticated, navigate]);

  const handleSuccess = (credentialResponse, action) => {
    console.log('Credential Response:', credentialResponse);
  
    const userObject = jwt_decode(credentialResponse.credential);
  
    console.log(userObject);
  
    const userData = {
      email: userObject.email,
      name: userObject.name,
      credential: credentialResponse.credential,
    };
  
    const endpoint = action === 'register' ? `${BACKEND_BASE_URL}/api/google_register/` : `${BACKEND_BASE_URL}/api/google_login/`;

    fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.status);
        }
        return response.json();
      })
      .then((data) => {
        if (data?.success === true) {
          console.log('Response:', data);
          const access = data.detail?.access
          const refresh = data.detail?.refresh
          const isAdmin = data.detail?.is_admin
          handleSignIn(isAdmin, userObject.email, userObject.name, access, refresh);
          navigate('/schedule-messages');
        } else {
          handleError(data?.success);
        }
      })
      .catch((error) => {
        console.log('Error:', error);
        handleError(error);
      });
  };
  
  const handleError = (error) => {
    console.log('Login Failed');
    // Handle login failure error
    const errorCard = document.createElement('div');
    errorCard.className = 'fixed top-0 left-0 w-screen h-screen flex justify-center items-center bg-black bg-opacity-50 z-50';

    const cardContent = document.createElement('div');
    cardContent.className = 'bg-white rounded-lg shadow-lg p-6';

    let responseCode = 'Unknown';

    if (error instanceof Response && error.status) {
      responseCode = error.status;
    } else if (error instanceof Error && error.message) {
      responseCode = error.message;
    }

    const errorMessage = document.createElement('p');
    errorMessage.className = 'text-red-600 text-lg font-semibold mb-4';
    errorMessage.textContent = `An error occurred while logging in. Response code: ${responseCode}`;

    const okButton = document.createElement('button');
    okButton.className = 'bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded';
    okButton.textContent = 'OK';
    okButton.addEventListener('click', () => {
      errorCard.remove();
    });

    cardContent.appendChild(errorMessage);
    cardContent.appendChild(okButton);
    errorCard.appendChild(cardContent);

    document.body.appendChild(errorCard);
  };

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const url = `${BACKEND_BASE_URL}/api/login/`
    setIsLoading(true);
    axios.post(url, formData)
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          console.log('Response:', response);
          const access = response.data?.access
          const refresh = response.data?.refresh
          const isAdmin = response.data?.is_admin
          handleSignIn(isAdmin, response.data?.email, response.data?.username, access, refresh);
          navigate('/schedule-messages');
        };
      }).catch((error) => {
        setIsLoading(false);
        setApiResponse(error.response.data);
        console.log("error", error);
      })
  };

  return (
    <>
      {
        isLoading && <Loader />
      }
      {apiResponse && (
        <ResponseModal
          apiResponse={apiResponse}
          onClick={() => { setIsLoading(false); setApiResponse(null); }}
        />
      )}

      <div className="flex justify-center items-center min-h-screen p-4">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
          <h1 className="text-[22px] sm:text-2xl md:text-3xl text-center font-bold mb-8">Sign In</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <input
                placeholder='Email'
                type='email'
                name='email'
                value={formData.email}
                onChange={(e) => handleChange(e)}
                className='w-full p-3 border border-gray-300 rounded outline-none mb-[16px]'
              />
            </div>
            <div>
              <input
                placeholder='Password'
                type='password'
                name='password'
                value={formData.password}
                onChange={(e) => handleChange(e)}
                className='w-full p-3 border border-gray-300 rounded outline-none mb-[16px]'
              />
            </div>
            <button
              type='submit'
              className={`${(/\S+@\S+\.\S+/.test(formData.email) && formData.password) ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-400"} text-white font-bold py-2 px-4 rounded w-full`}
              disabled={!(/\S+@\S+\.\S+/.test(formData.email) && formData.password)}
            >
              Sign In
            </button>
          </form>
          <div className='text-center flex flex-col items-center'>
            <h2 className="m-2 text-center">or</h2>
            <GoogleLogin
              onSuccess={(response) => handleSuccess(response, 'login')}
              onError={handleError}
              type="standard"
              theme="outline"
              size="medium"
              text="signin_with"
              shape="rectangular"
              logo_alignment="center"
            />

            <h2 className="mt-[50px] text-center">New user?</h2>

            <GoogleLogin
              onSuccess={(response) => handleSuccess(response, 'register')}
              onError={handleError}
              type="standard"
              theme="outline"
              size="medium"
              text="signup_with"
              shape="rectangular"
              logo_alignment="center"
            />
          </div>
          <h2 className="mt-2 text-center">or</h2>
          <div className='text-center'>
            <Link to='/signUp' className='underline text-blue-500 hover:text-blue-700'>Click to Sign Up Manually</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
