import React from 'react';

const DialogLoader = () => {
    return (
        <div className='absolute inset-0 z-0 '>
            <div className=' h-full grid place-items-center'>
                <div>
                    <p class="loading__text">Loading...</p>
                    <div class="loading__bar"></div>
                </div>
            </div>
        </div>
    )
};

export default DialogLoader;
