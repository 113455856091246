import React from 'react';

function PricingPage() {
  return (
    <div className="px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">Pricing</h1>
      <p className="mb-8">
        This is the other page.
      </p>
    </div>
  );
}

export default PricingPage;
