import axios from 'axios';
import React, { useRef, useState } from 'react';
import Loader from '../components/Loader';
import ResponseModal from '../components/ResponseModal';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import { useAuth } from '../components/context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const navigate = useNavigate();
    const fileInputRef = useRef();
    const { accessToken, handleSignOut } = useAuth();
    const [isLoading, setIsLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState(null);
    const [inboundMessages, setInboundMessages] = useState([]);
    const initialState = {
        file: null,
        header: ''
    };
    const [data, setdata] = useState(initialState);

    const handleFile = () => {
        setIsLoading(true);
        const url = `${BACKEND_BASE_URL}/api/inbound_sms/`
        const headers = {
            'content-type': 'multipart/form-data',
            'Authorization': `Bearer ${accessToken}`
        };

        axios.post(url, data, { headers: headers })
            .then((response) => {
                setIsLoading(false);
                setdata(initialState);
                fileInputRef.current.value = null;
                if (response.status === 200) {
                    setInboundMessages(response.data);
                };
            }).catch((error) => {
                setIsLoading(false);
                setApiResponse(error.response.data);
                setInboundMessages([]);
                fileInputRef.current.value = null;
                setdata(initialState);
                console.log("error", error);
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut();
                    navigate('/login');
                };
            })
    };

    return (
        <>
            {
                isLoading && <Loader />
            }
            {apiResponse && (
                <ResponseModal
                    apiResponse={apiResponse}
                    onClick={() => { setIsLoading(false); setApiResponse(null); }}
                />
            )}

            <div className='bg-gray-50'>
                <div className='max-w-[1200px] mx-auto p-[50px] max-md:p-[30px] max-sm:p-[20px]'>
                    <div className='sm:flex justify-between border border-gray-200 mb-3 p-1'>
                        <div className='flex-1 m-1'>
                            <input
                                id="csvFile"
                                name="distribution_list"
                                type="file"
                                accept=".csv"
                                onChange={(e) => {
                                    if (e.target.files[0] && e.target.files[0].size <= 5 * 1024 * 1024) {
                                        setdata({ ...data, file: e.target.files[0] });
                                    } else {
                                        e.target.value = null;
                                        fileInputRef.current.value = null;
                                        setdata({ ...data, file: null });
                                    };
                                }}
                                ref={fileInputRef}
                                onClick={() => { fileInputRef.current.value = null; setdata({...data, file: null}); }}
                                className="w-full p-2 border border-gray-300 rounded accent-gray-600 select-none hover:border-gray-400"
                            />
                            <p className="text-sm text-gray-500 mt-1">
                                Upload a list(csv) of confirmation words
                            </p>
                        </div>
                        <div className='flex-1 m-1'>
                            <input
                                type='text'
                                id='headerName'
                                name='header'
                                placeholder='Enter header name'
                                value={data.header}
                                onChange={(e) => setdata({ ...data, header: e.target.value })}
                                className='w-full p-2 border min-h-[47px] border-gray-300 outline-none rounded'
                            />
                            <p className="text-sm text-gray-500 mt-1">
                                Enter the header/column name of Confirmation Words
                            </p>
                        </div>
                        <div
                            className={`border px-6 py-2 h-[47px] text-center flex m-1 items-center ${data.file && data.header ? 'bg-blue-600 hover:bg-blue-500 shadow-lg cursor-pointer' : 'bg-gray-400 cursor-not-allowed'} select-none rounded`}
                            onClick={(data.file && data.header) ? () => handleFile() : null}
                        >
                            <button className={`text-[18px] text-white font-semibold ${data.file && data.header ? '' : 'cursor-not-allowed'}`}>Submit</button>
                        </div>
                    </div>
                    <h1 className='mb-2 text-center text-[25px]'>Appointments Confirmation</h1>
                    <div className='overflow-x-auto rounded-[8px] shadow-md bg-white'>
                        <table className="table-auto max-w-[1100px] w-full mx-auto">
                            <thead>
                                <tr>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                        sent Date
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                        from
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                        to
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid text-center'>
                                        status
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase text-center break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid'>
                                        response
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {inboundMessages && inboundMessages?.map((msg, index) => (
                                    <tr className='border-[#e1e3ea] border-b-[1px]' key={index}>
                                        <td className='py-[16px] whitespace-nowrap px-[16px]'>{msg.date_sent}</td>
                                        <td className='py-[16px] whitespace-nowrap px-[16px]'>{msg.from}</td>
                                        <td className='py-[16px] whitespace-nowrap px-[16px]'>{msg.to}</td>
                                        <td className='text-center capitalize text-orange-500'>{msg.status}</td>
                                        <td className='text-center whitespace-nowrap font-bold'>{msg.body}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {
                            !inboundMessages.length && <div className='my-[30px] text-center text-[23px]'>There are no messages to display</div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboard;
