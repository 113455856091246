import React from 'react';

function Footer() {
  return (
    <div className="bg-gray-900 text-white py-4">
      <div className="container mx-auto">
        <p className="text-center">
          SMS Services Company<br />
          123 Main Street<br />
          Anytown, USA<br />
          Phone: 555-555-5555<br />
        </p>
      </div>
    </div>
  );
}

export default Footer;
