import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useNavigate } from 'react-router-dom';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import { useAuth } from '../components/context/AuthContext';
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai';
import { FaUserCheck, FaUserTimes, FaUserEdit, FaUserClock, FaUserLock } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Dialog } from '@headlessui/react'
import Popup from '../components/Popup';
import Loader from '../components/Loader';
import ResponseModal from '../components/ResponseModal';
import { BiSolidUserDetail, BiUserPlus } from 'react-icons/bi';

const AdminPage = () => {
    const navigate = useNavigate();
    const { handleSignOut, accessToken } = useAuth();

    const [usersData, setUsersData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [apiResponse, setApiResponse] = useState(null);

    // Conformation popUp state
    const [isConform, setIsConform] = useState(false);
    const [action, setAction] = useState('');
    const [actionId, setActionId] = useState(null);

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    useEffect(() => {
        fetchUsers(currentPage);
        // eslint-disable-next-line
    }, [])

    const fetchUsers = (page) => {
        setIsLoading(true)
        axios.get(`${BACKEND_BASE_URL}/api/admin_user?page=${page}`, {
            headers: headers,
        }).then((response) => {
            setIsLoading(false)
            if (response.status === 200) {
                setUsersData(response?.data?.results)
                setPageCount(response?.data?.total_pages)
                setCurrentPage(response?.data?.current_page_number)
            }
        }).catch((error) => {
            setIsLoading(false)
            console.log("error", error)
            if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                handleSignOut()
                navigate('/login')
            };
        })
    };

    const handlePageChange = (page) => {
        let currentPage = page.selected
        setCurrentPage(currentPage + 1);
        fetchUsers(currentPage + 1);
    };

    const handleDisable = () => {
        if (action && actionId) {
            axios.delete(`${BACKEND_BASE_URL}/api/admin_user/${actionId}/?action=${action}`, {
                headers: headers,
            }).then((response) => {
                if (response.status === 200) {
                    setApiResponse(response?.data)
                    fetchUsers(currentPage);
                }
            }).catch((error) => {
                console.log("error", error)
                if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                    handleSignOut()
                    navigate('/login')
                };
            })
        };
        setIsConform(false);
    };

    const closeErrorDialog = () => {
        setIsConform(true);
    };

    const handleAction = (id, action) => {
        setActionId(id);
        setAction(action);
        setIsConform(true);
    };

    return (
        <>
        {
            isLoading && <Loader />
        }
        {apiResponse && (
            <ResponseModal
                apiResponse={apiResponse}
                onClick={() => { setIsLoading(false); setApiResponse(null); }}
            />
        )}

        <Popup
            isOpen={isConform}
            closeCSVDialog={closeErrorDialog}
            className="p-4 rounded-md"
        >
            <div>
                <div className="text-center">
                    <Dialog.Title as="h3" className=" font-semibold leading-6 text-xl text-yellow-500">
                        Confirmation
                    </Dialog.Title>
                    <div className="otp-input-container mt-2 flex gap-2 justify-center text-gray-900">
                        {
                            action === 'time_restriction'
                            ? <p>Are you sure to <strong>disable/enable the time restriction</strong> for this user?</p>
                            : action === 'admin'
                            ? <p>Are you sure to <strong>grant/revoke permission</strong> as admin to this user?</p>
                            : (action === 'deactivate' || action === 'activate')
                            ? <p>Are you sure to <strong>{action ? action : 'deactivate/activate'}</strong> this user?</p>
                            : ''
                        }
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-6 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 flex justify-end">
                <button
                    type="button"
                    className="mt-3 outline-none bg-slate-400 text-white inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-1 sm:mt-0"
                    onClick={() => { setIsConform(false); setAction(''); setActionId(null); }}
                >
                    No
                </button>
                <button
                    type="button"
                    className="mt-3 outline-none bg-blue-500 text-white inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-1 sm:mt-0"
                    onClick={handleDisable}
                >
                    Yes
                </button>
            </div>
        </Popup>

        <div className='bg-gray-50 min-h-[calc(100vh_-_64px_-_128px)]'>
            <div className='max-w-[100%] mx-auto p-[50px] max-md:p-[30px] max-sm:p-[20px]'>
                <div className='flex items-center justify-between mb-5'>
                    <div></div>
                    <h1 className='text-center text-[20px] sm:text-[25px]'>Manage Users</h1>
                    <div className='text-right'>
                        <button
                            type="submit"
                            title='create new user'
                            className="hidden md:inline p-3 bg-[#1F2937] text-white font-semibold rounded"
                            onClick={() => navigate('/admin/create-user')}
                        >
                            Create New User
                        </button>
                        <button
                            type="submit"
                            title='create new user'
                            className="inline md:hidden p-2 bg-[#1F2937] text-[20px] text-white font-semibold rounded"
                            onClick={() => navigate('/admin/create-user')}
                        >
                            <BiUserPlus />
                        </button>
                    </div>
                </div>
                <div className='overflow-x-auto w-full rounded-[8px] shadow-md bg-white'>
                    <table className="table-auto w-full mx-auto">
                        <thead>
                            <tr>
                                <th className='bg-slate-200 whitespace-nowrap bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                    joined date
                                </th>
                                <th className='bg-slate-200 whitespace-nowrap bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                    email
                                </th>
                                <th className='bg-slate-200 whitespace-nowrap bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid text-left'>
                                    Mobile No
                                </th>
                                <th className='bg-slate-200 whitespace-nowrap bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid text-left'>
                                    provider
                                </th>
                                <th className='bg-slate-200 whitespace-nowrap bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid'>
                                    sent messages
                                </th>
                                <th className='bg-slate-200 whitespace-nowrap bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid'>
                                    OTP verification
                                </th>
                                <th className='bg-slate-200 whitespace-nowrap bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid'>
                                    Time Restriction
                                </th>
                                <th className='bg-slate-200 whitespace-nowrap bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid'>
                                    is active
                                </th>
                                <th className='bg-slate-200 whitespace-nowrap bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid'>
                                    is admin
                                </th>
                                <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid'>
                                    action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersData && usersData?.map((user, index) => (
                                <tr className='border-[#e1e3ea] border-b-[1px]' key={index}>
                                    <td className='py-[16px] whitespace-nowrap px-[16px]'>
                                        {user.date_joined ? user.date_joined : '-'}
                                    </td>
                                    <td className='py-[16px] whitespace-nowrap px-[16px]'>
                                        {user.email ? user.email : '-'}
                                    </td>
                                    <td className='py-[16px] whitespace-nowrap px-[16px]'>
                                        {user.mobile_no ? `+${user.mobile_no}` : '-'}
                                    </td>
                                    <td className='py-[16px] whitespace-nowrap px-[16px]'>
                                        {user.provider?.name ? user.provider.name : '-'}
                                    </td>
                                    <td className='text-center whitespace-nowrap py-[16px] px-[16px]'>
                                        {user.messages_sent}
                                    </td>
                                    <td className='text-center whitespace-nowrap py-[16px] px-[16px]'>
                                        {
                                            user.is_otp_verification ? (
                                                <AiFillCheckCircle size={"24px"} className='mx-auto text-green-600' />
                                            ) : (
                                                <AiFillCloseCircle size={"24px"} className='mx-auto text-rose-600' />
                                            )
                                        }
                                    </td>
                                    <td className='text-center whitespace-nowrap py-[16px] px-[16px]'>
                                        {
                                            user.is_time_restriction ? (
                                                <AiFillCheckCircle size={"24px"} className='mx-auto text-green-600' />
                                            ) : (
                                                <AiFillCloseCircle size={"24px"} className='mx-auto text-rose-600' />
                                            )
                                        }
                                    </td>
                                    <td className='text-center whitespace-nowrap py-[16px] px-[16px]'>
                                        {
                                            user.is_active ? (
                                                <AiFillCheckCircle size={"24px"} className='mx-auto text-green-600' />
                                            ) : (
                                                <AiFillCloseCircle size={"24px"} className='mx-auto text-rose-600' />
                                            )
                                        }
                                    </td>
                                    <td className='text-center py-[16px] px-[16px]'>
                                        {
                                            user.is_staff ? (
                                                <AiFillCheckCircle size={"24px"} className='mx-auto text-green-600' />
                                            ) : (
                                                <AiFillCloseCircle size={"24px"} className='mx-auto text-rose-600' />
                                            )
                                        }                                            
                                    </td>
                                    <td className='text-center whitespace-nowrap cursor-pointer'>
                                        <FaUserClock
                                            size={"25px"}
                                            className='mx-auto inline mr-2'
                                            data-tooltip-id='timeRestriction'
                                            onClick={() => handleAction(user.id, 'time_restriction')}
                                        />
                                        <FaUserLock
                                            size={"25px"}
                                            className='mx-auto inline mr-2'
                                            data-tooltip-id='admin'
                                            onClick={() => handleAction(user.id, 'admin')}
                                        />
                                        {
                                            user.is_active ? (
                                                <FaUserTimes
                                                    size={"25px"}
                                                    className='mx-auto inline mr-2'
                                                    data-tooltip-id='deactivate'
                                                    onClick={() => handleAction(user.id, 'deactivate')}
                                                />
                                            ) : (
                                                <FaUserCheck
                                                    size={"25px"}
                                                    className='mx-auto inline mr-2'
                                                    data-tooltip-id='activate'
                                                    onClick={() => handleAction(user.id, 'activate')}
                                                />
                                            )
                                        }
                                        <FaUserEdit
                                            size={"25px"}
                                            className='mx-auto inline mr-1'
                                            data-tooltip-id='profile'
                                            onClick={() => navigate(`/admin/profile/${user.id}`, {state: 'admin'})}
                                        />
                                        <BiSolidUserDetail
                                            size={"33px"}
                                            className='mx-auto inline'
                                            data-tooltip-id='details'
                                            onClick={() => navigate(`/admin/detail/${user.id}`)}
                                        />
                                    </td>
                                    <ReactTooltip id='deactivate' content='DeActivate' className='!bg-[#121c2d]' />
                                    <ReactTooltip id='activate' content='Activate' className='!bg-[#121c2d]' />
                                    <ReactTooltip id='profile' content='Edit Profile' className='!bg-[#121c2d]' />
                                    <ReactTooltip id='details' content='Detail' className='!bg-[#121c2d]' />
                                    <ReactTooltip id='timeRestriction' content='Time Restriction' className='!bg-[#121c2d]' />
                                    <ReactTooltip id='admin' content='Make Admin' className='!bg-[#121c2d]' />
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {
                        !usersData?.length && <div className='my-[30px] text-center text-[23px]'>There are no users to display</div>
                    }
                </div>
                {
                    pageCount > 1 && (
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="Next >"
                            onPageChange={handlePageChange}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            currentPage={currentPage}
                            previousLabel="< Previous"
                            renderOnZeroPageCount={null}
                            className='flex justify-center gap-[20px] mt-[30px] items-center'
                            pageLinkClassName='hover:bg-blue-600 grid place-items-center hover:text-white h-[33px] inline-block w-[33px]  rounded-full'
                            previousClassName='bg-[#111827] text-[12px] md:text-[16px] text-white p-2 rounded'
                            nextClassName='bg-[#111827] text-[12px] md:text-[16px] text-white p-2 rounded'
                            activeLinkClassName='bg-blue-500 place-items-center text-white py-[5px] px-[8px] h-[33px] inline-block w-[33px] rounded-full'
                        />
                    )
                }
            </div>
        </div>
        </>
    )
};

export default AdminPage;
