import React, { createContext, useContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated') === 'true');
  const [isAdmin, setIsAdmin] = useState(localStorage.getItem('isAdmin') === 'true');
  const [email, setEmail] = useState(localStorage.getItem('email') || '');
  const [name, setName] = useState(localStorage.getItem('name') || '');
  const [accessToken, setAccessToken] = useState(localStorage.getItem('accessToken') || '');
  const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken') || '');
  const [OTPAuthTime, setOTPAuthTime] = useState(localStorage.getItem('OTPAuthTime') || '');

  const handleSignIn = (isAdminUser, email, name, access, refresh) => {
    setIsAuthenticated(true);
    setIsAdmin(isAdminUser);
    setEmail(email);
    setName(name);
    setAccessToken(access);
    setRefreshToken(refresh);
    localStorage.setItem('isAuthenticated', true);
    localStorage.setItem('isAdmin', isAdminUser);
    localStorage.setItem('email', email);
    localStorage.setItem('name', name);
    localStorage.setItem('accessToken', access);
    localStorage.setItem('refreshToken', refresh);
    /* // Set HttpOnly cookie
    document.cookie = `token=${token}; HttpOnly`;

    localStorage.setItem('token', token);
    */
  };
  

  const handleSignOut = () => {
    setIsAuthenticated(false);
    setIsAdmin(false);
    setEmail('');
    setName('');
    setAccessToken('');
    setRefreshToken('');
    setOTPAuthTime('');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('OTPAuthTime');
  };

  const handleOTPAuth = (time) => {
    setOTPAuthTime(time);
    localStorage.setItem('OTPAuthTime', time);
  };

  useEffect(() => {
    // Check local storage for authentication state and email
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    const storedIsAdmin = localStorage.getItem('isAdmin') === 'true';
    const storedEmail = localStorage.getItem('email') || '';
    const storedName = localStorage.getItem('name') || '';
    const storedAccessToken = localStorage.getItem('accessToken') || '';
    const storedRefreshToken = localStorage.getItem('refreshToken') || '';
    const storedOTPAuthTime = localStorage.getItem('OTPAuthTime') || '';

    // Set state based on local storage values
    setIsAuthenticated(storedIsAuthenticated);
    setIsAdmin(storedIsAdmin);
    setEmail(storedEmail);
    setName(storedName);
    setAccessToken(storedAccessToken);
    setRefreshToken(storedRefreshToken);
    setOTPAuthTime(storedOTPAuthTime);
  }, []);

  const authContextValue = {
    isAuthenticated,
    isAdmin,
    email,
    name,
    accessToken,
    refreshToken,
    OTPAuthTime,
    handleOTPAuth,
    handleSignIn,
    handleSignOut,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};
