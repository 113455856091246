// export function countCharactersAndSegments(message) {
//     const charCount = message?.length;
  
//     let segments;
//     if (charCount <= 160) {
//       segments = 1;
//     } else {
//       segments = Math.ceil(charCount / 153);
//     }
  
//     return { charCount, segments };
//   }


export function countCharactersAndSegments(message) {
  const GSM_7BIT_MAX_LENGTH = 160;
  const UNICODE_MAX_LENGTH = 70;

  let charCount = message?.length;
  let segmentLimit = GSM_7BIT_MAX_LENGTH;
  let segments

  // Check for non-GSM characters and switch to Unicode encoding if necessary
  for (let i = 0; i < charCount; i++) {
    const charCode = message.charCodeAt(i);
    if (charCode > 127) {
      segmentLimit = UNICODE_MAX_LENGTH;
      break;
    }
  };

  segments = Math.ceil(charCount / segmentLimit);

  segments = segments ? segments : 1

  return { charCount, segments };
};
