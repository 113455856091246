import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import Loader from './Loader';
import ResponseModal from './ResponseModal';

const SignUp = () => {
  const navigate = useNavigate();
  const initialState = {
    email: '',
    first_name: '',
    last_name: '',
    password: ''
  };
  const [formData, setFormData] = useState(initialState);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [apiResponse, setApiResponse] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const url = `${BACKEND_BASE_URL}/api/register/`
    if (formData.password === confirmPassword) {
      setIsLoading(true);
      axios.post(url, formData)
        .then((response) => {
          setIsLoading(false);
          if (response.status === 200) {
            navigate('/login');
          };
        }).catch((error) => {
          setIsLoading(false);
          setApiResponse(error.response.data);
          console.log("error", error);
        })
    } else {
      setApiResponse("Password and Confirm Password do not match.")
    }
  };

  return (
    <>
      {
        isLoading && <Loader />
      }
      {apiResponse && (
        <ResponseModal
          apiResponse={apiResponse}
          onClick={() => { setIsLoading(false); setApiResponse(null); }}
        />
      )}

      <div className="flex justify-center items-center min-h-screen p-4">
        <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
          <h1 className="re text-center font-bold mb-8">Sign Up</h1>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="mb-[5px] text-[14px] sm:text-[16px] capitalize block">
                <span className="text-red-600">*</span>
                email
              </label>
              <input
                id='email'
                type='email'
                name='email'
                value={formData.email}
                onChange={(e) => handleChange(e)}
                className='w-full p-3 border border-gray-300 rounded outline-none mb-[16px]'
              />
            </div>
            <div>
              <label htmlFor="firstName" className="mb-[5px] text-[14px] sm:text-[16px] capitalize block">
                first name
              </label>
              <input
                id='firstName'
                type='text'
                name='first_name'
                value={formData.first_name}
                onChange={(e) => handleChange(e)}
                className='w-full p-3 border border-gray-300 text-[14px] sm:text-[16px] rounded outline-none mb-[16px]'
              />
            </div>
            <div>
              <label htmlFor="lastName" className="mb-[5px] text-[14px] sm:text-[16px] capitalize block">
                last name
              </label>
              <input
                id='lastName'
                type='text'
                name='last_name'
                value={formData.last_name}
                onChange={(e) => handleChange(e)}
                className='w-full p-3 border border-gray-300 text-[14px] sm:text-[16px] rounded outline-none mb-[16px]'
              />
            </div>
            <div>
              <label htmlFor="password" className="mb-[5px] text-[14px] sm:text-[16px] capitalize block">
                <span className="text-red-600">*</span>
                password
              </label>
              <input
                id='password'
                type='password'
                name='password'
                value={formData.password}
                onChange={(e) => handleChange(e)}
                className='w-full p-3 border border-gray-300 text-[14px] sm:text-[16px] rounded outline-none mb-[16px]'
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="mb-[5px] text-[14px] sm:text-[16px] capitalize block">
                <span className="text-red-600">*</span>
                confirm password
              </label>
              <input
                id='confirmPassword'
                type='password'
                name='confirm_password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className='w-full p-3 border border-gray-300 text-[14px] sm:text-[16px] rounded outline-none mb-[16px]'
              />
            </div>
            <button
              type='submit'
              className={`${(/\S+@\S+\.\S+/.test(formData.email) && formData.password && confirmPassword) ? "bg-blue-600 hover:bg-blue-700" : "bg-gray-400"} text-white font-bold py-2 px-4 rounded w-full`}
              disabled={!(/\S+@\S+\.\S+/.test(formData.email) && formData.password && confirmPassword)}
            >
              Sign Up
            </button>
          </form>
          <div className='text-center mt-5'>
            <Link to='/login' className='underline text-blue-500 hover:text-blue-700'>Back to Sign In</Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
