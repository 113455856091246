import React from 'react';

const Loader = () => {
  return (
      <div className='fixed z-50 inset-0 bg-black bg-opacity-40 overflow-hidden transition-all duration-[0.3]'>
          <div className='flex justify-center items-center w-full h-full '>
              <div className="spinner absolute w-[9px] h-[9px]">
                  <div className='absolute w-1/2 h-[150%] bg-white'></div>
                  <div className='absolute w-1/2 h-[150%] bg-white'></div>
                  <div className='absolute w-1/2 h-[150%] bg-white'></div>
                  <div className='absolute w-1/2 h-[150%] bg-white'></div>
                  <div className='absolute w-1/2 h-[150%] bg-white'></div>
                  <div className='absolute w-1/2 h-[150%] bg-white'></div>
                  <div className='absolute w-1/2 h-[150%] bg-white'></div>
                  <div className='absolute w-1/2 h-[150%] bg-white'></div>
                  <div className='absolute w-1/2 h-[150%] bg-white'></div>
                  <div className='absolute w-1/2 h-[150%] bg-white'></div>
              </div>
          </div>
      </div>
  )
};

export default Loader;