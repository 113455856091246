import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { BsEyeFill } from 'react-icons/bs';
import { FaEdit } from 'react-icons/fa';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useNavigate } from 'react-router-dom';
import { BACKEND_BASE_URL } from '../components/common/apiEnv';
import { useAuth } from '../components/context/AuthContext';
import ReactPaginate from 'react-paginate';
import Loader from '../components/Loader';
import ResponseModal from '../components/ResponseModal';
import { TbReload } from "react-icons/tb";
import Popup from '../components/Popup';
import { Dialog } from '@headlessui/react';

const ScheduleSmsPage = () => {
    const navigate = useNavigate();
    const { handleSignOut, accessToken } = useAuth();

    const [messagesData, setMessagesData] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [apiResponse, setApiResponse] = useState(null);
    const [filterBy, setFilterBy] = useState('24');
    const [spin, setSpin] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
    };

    useEffect(() => {
        fetchMessages(currentPage, filterBy);
        // eslint-disable-next-line
    }, [])

    const fetchMessages = (page, filterBy) => {
        setIsLoading(true)
        axios.get(`${BACKEND_BASE_URL}/api/schedule_sms?filter_by=${filterBy}&page=${page}`, {
            headers: headers,
        }).then((response) => {
            setIsLoading(false)
            if (response.status === 200) {
                setMessagesData(response?.data?.results)
                setPageCount(response?.data?.total_pages)
                setCurrentPage(response?.data?.current_page_number)
            }
        }).catch((error) => {
            setIsLoading(false)
            console.log("error", error)
            if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                handleSignOut()
                navigate('/login')
            };
        })
    };

    const handlePageChange = (page) => {
        let currentPage = page.selected
        setCurrentPage(currentPage + 1);
        fetchMessages(currentPage + 1, filterBy);
    };

    const handleCancelTask = (id) => {
        axios.delete(`${BACKEND_BASE_URL}/api/schedule_sms/${id}/`, {
            headers: headers,
        }).then((response) => {
            if (response.status === 200) {
                setApiResponse(response?.data)
                fetchMessages(currentPage, filterBy);
            }
        }).catch((error) => {
            console.log("error", error)
            if (error?.response?.status === 401 || error?.response?.statusText === "Unauthorized") {
                handleSignOut()
                navigate('/login')
            };
        })
    };

    const handleFilter = (filter) => {
        setFilterBy(filter);
        fetchMessages(1, filter);
    };

    const refreshCanvas = () => {
        setSpin(true);
        setTimeout(() => {
            setSpin(false);
        }, 1000);
    };

    const closeDialog = () => {
        setIsOpen(true);
    };

    return (
        <>
            {
                isLoading && <Loader />
            }
            {apiResponse && (
                <ResponseModal
                    apiResponse={apiResponse}
                    onClick={() => { setIsLoading(false); setApiResponse(null); }}
                />
            )}

            <Popup
                isOpen={isOpen}
                closeCSVDialog={closeDialog}
                className="p-4 rounded-md sm:max-w-[50em]"
            >
                <div>
                    <div className="text-center">
                        <Dialog.Title as="h3" className=" font-semibold leading-6 text-xl text-rose-400">
                            Error Message
                        </Dialog.Title>
                        <div className="otp-input-container mt-4 flex gap-2 justify-center text-gray-800 border-t border-b py-1">
                            <pre className='max-w-[750px] overflow-x-auto lg:max-h-[700px] overflow-y-auto max-h-[450px] p-2 whitespace-pre-line'>{errorMsg}</pre>
                        </div>
                    </div>
                </div>
                <div className="mt-3 sm:mt-3 sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3 flex justify-end">
                    <button
                        type="button"
                        className="mt-3 outline-none bg-slate-400 text-white inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 sm:col-start-1 sm:mt-0"
                        onClick={() => { setIsOpen(false); }}
                    >
                        Close
                    </button>
                </div>
            </Popup>

            <div className='bg-gray-50'>
                <div className='max-w-[1200px] mx-auto p-[50px] max-md:p-[30px] max-sm:p-[20px]'>
                    <div className='sm:flex justify-between mb-5'>
                        <div></div>
                        <div></div>
                        <h1 className='text-center text-[25px] sm:text-[25px] mb-[15px]'>Scheduled Message's Logs</h1>
                        <div className='flex items-end sm:items-center text-left  sm:text-right'>
                            <div className='w-full sm:w-auto'>
                                <label htmlFor="filterBy" className="m-[5px] font-medium capitalize">
                                    filter by
                                </label>
                                <select
                                    id="filterBy"
                                    name="filter_by"
                                    onChange={(e) => handleFilter(e.target.value)}
                                    value={filterBy}
                                    className="p-[9px] w-full sm:w-auto flex-1 sm:px-[12px] sm:py-2.5 border border-slate-300 bg-slate-100 cursor-pointer outline-none rounded"
                                >
                                    <option key='1' value='24'>24 Hours</option>
                                    <option key='2' value='7'>7 Days</option>
                                    <option key='3' value=''>All</option>
                                </select>
                            </div>
                            <button
                                type="submit"
                                className="w-auto ml-2 sm:mt-0 inline p-3 bg-[#1F2937] text-white font-semibold rounded"
                                onClick={() => { fetchMessages(1, '24'); setCurrentPage(1); setFilterBy('24'); refreshCanvas()}}
                            >
                                <TbReload className={spin ? 'animate-spin' : ''}/>
                            </button>
                        </div>
                    </div>
                    <div className='overflow-x-auto rounded-[8px] shadow-md bg-white'>
                        <table className="table-auto max-w-[1100px] w-full mx-auto">
                            <thead>
                                <tr>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                        Created Date
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                        Schedule Date
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative text-left break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid uppercase'>
                                        from
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase text-center break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid'>
                                        message
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid text-center'>
                                        status
                                    </th>
                                    <th className='bg-slate-200 bg-opacity-40 text-[rgb(18,28,45)] box-border text-[14px] leading-5 font-semibold relative uppercase break-words px-4 py-3 border-b-[rgb(225,227,234)] border-b-[2px] border-solid text-center'>
                                        action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {messagesData && messagesData?.map((msg, index) => (
                                    <tr className='border-[#e1e3ea] border-b-[1px]' key={index}>
                                        <td className='py-[16px] whitespace-nowrap px-[16px]'>{msg.created_at}</td>
                                        <td className='py-[16px] whitespace-nowrap px-[16px]'>{msg.scheduled_date ? msg.scheduled_date : '-'}</td>
                                        <td className='py-[16px] whitespace-nowrap px-[16px]'>{msg.from_number}</td>
                                        <td className='whitespace-nowrap'><BsEyeFill size={"21px"} className='mx-auto text-blue-500 cursor-pointer' data-tooltip-id={msg.id} /></td>
                                        <td className={`text-center ${msg.status === "Scheduled" ? "text-orange-500" : msg.status === "Failed" ? "text-rose-500" : msg.status === "Delivered" ? "text-green-500" : ''}`}>{msg.status}</td>
                                        {msg.status === 'Scheduled' && !msg.is_send_asap ?
                                            <td className='text-center cursor-pointer'>
                                                <MdOutlineCancel
                                                    size={"25px"}
                                                    className='mx-auto mr-2 text-rose-500 inline hover:text-rose-600'
                                                    data-tooltip-id='cancel'
                                                    onClick={() => handleCancelTask(msg.id)}
                                                />
                                                <FaEdit
                                                    size={"25px"}
                                                    className='mx-auto inline'
                                                    data-tooltip-id='edit'
                                                    onClick={() => navigate(`/scheduled-message/${msg.id}`)}
                                                />
                                            </td>
                                            : msg.status === 'Failed' ?
                                                <td
                                                    className='text-center cursor-pointer hover:underline hover:text-[#619ce9]'
                                                    onClick={() => { setIsOpen(true); setErrorMsg(msg.error_msg); }}
                                                >
                                                    View_error
                                                </td>
                                                : <td className='text-center cursor-pointer'> - </td>
                                        }
                                        <ReactTooltip id='cancel' content='Cancel' className='!bg-[#121c2d]' />
                                        <ReactTooltip id='edit' content='Edit' className='!bg-[#121c2d]' />
                                        <ReactTooltip id={msg.id} content={msg.message_body} className='!bg-[#121c2d] max-w-[300px] max-h-[200px] w-full text-justify whitespace-pre-line' />
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {
                            !messagesData?.length && <div className='my-[30px] text-center text-[23px]'>There are no scheduled messages to display</div>
                        }
                    </div>
                    {
                        pageCount > 1 && (
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next >"
                                onPageChange={handlePageChange}
                                pageRangeDisplayed={5}
                                pageCount={pageCount}
                                forcePage={currentPage-1}
                                previousLabel="< Previous"
                                renderOnZeroPageCount={null}
                                className='flex justify-center gap-[20px] mt-[30px] items-center'
                                pageLinkClassName='hover:bg-blue-600 grid place-items-center hover:text-white h-[33px] inline-block w-[33px]  rounded-full'
                                previousClassName='bg-[#111827] text-white p-2 rounded'
                                nextClassName='bg-[#111827] text-white p-2 rounded'
                                activeLinkClassName='bg-blue-500 place-items-center text-white py-[5px] px-[8px] h-[33px] inline-block w-[33px] rounded-full'
                            />
                        )
                    }
                </div>
            </div>
        </>
    )
};

export default ScheduleSmsPage;
